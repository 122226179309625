.activity-template-ls-default {
    .template-header, .template-content, .template-actions, .MuiDialogContent-root {
        background-color: white;
        .chipStyle {
            color: #008C95;
            border: 2px solid #008C95;
            background: #FFFFFF;
        }
        .chipSelected {
            color: white;
            background: #008C95;  
        }
        
    }
   .MuiButton-contained.Mui-disabled {
        background-color: #008C95;
        color: #FFFFFF;
    }
    .rct-block-content{
        background:  white;
    }
    .MuiDialog-paperFullWidth{
        width: calc(100% - 16px);
    }
    .MuiDialog-paper {
        margin: 8px;
        position: relative;
        overflow-y: clip;
    }
    .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 16px);
    }
}
.activity-template-aus-default {
    .MuiDialog-paperFullWidth{
        width: calc(100% - 16px);
    }
    .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 16px);
    }
    .MuiDialog-paper {
        margin: 8px;
        position: relative;
        overflow-y: clip;
    }

    .MuiPaper-root, .template-header, .template-actions, .MuiDialogActions-root {
        background-color: rgba(49,49,50,1.0);
        .listItemTitle{
            color: white;
        }
        .helpbutton {
            background: rgba(49,49,50,.95);
            color: red;
            border: 2px solid red;
        }
        .activityPreviewBackbutton{
            background-color:red;
            color: #FFFFFF;
        }
        .activityPreviewBackbutton:hover, .activityPreviewBackbutton:focus{
            background-color:rgba(49,49,50,.95);
            color:  red;
            border: 0px solid red;
            .MuiSvgIcon-root{
                color:  red;
            }
        }
        .backbutton{
            background-color:red;
            color: #FFFFFF;
            border: 0px solid #FFFFFF;
            .MuiSvgIcon-root{
                color: #FFFFFF;
            }
        }
        .backbutton:hover, .backbutton:focus{
            background-color:rgba(49,49,50,.95);
            color:  red;
            border: 2px solid red;
            .MuiSvgIcon-root{
                color:  red;
            }
        }
        .helpbutton:hover, .helpbutton:focus {
            background-color:red;
            color:  #ffffff;
            border: 0px solid rgba(49,49,50,.95);
        }
        .helpbutton:active, .backbutton:active{
            background-color:red;
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, .14 ), 0px 3px 14px 2px rgba(0, 0, 0, .12);
        }
    }
    .template-content, .MuiDialogContent-root {
        background-color: rgba(49,49,50,1.0);
        .steptitle, .listItemTitle{
            color: white;
        }
        .detailslistItem {
            color: white;
        }
        p {
            color: white;
        }
        .chip-array {
            .MuiChip-root {
                color: red;
                background-color: rgb(60,60,60);
                border: 2px solid red;
            }
            .MuiChip-clickable:hover, .MuiChip-clickable:focus {
                background-color:rgb(60,60,60);
            }
        }
        .chip-array-selected {
            .MuiChip-root {
                background: red;
                color: white;
                border: 2px solid red;
            }
            .MuiChip-clickable:hover, .MuiChip-clickable:focus {
                background-color:red;
            }
            .badge-danger{
                background: white;
                color: red;
            }
        }
        .activityPreviewCloseButton {
            color: #CB251E;
            border: 1px solid #CB251E;
            background-color: #FFFFFF;
        }
        .activityPreviewCloseButton:hover {
            background-color: #CB251E;
            color:#FFFFFF !important;
            border: 1px solid #FFFFFF;
        }
        .menuEditItem, .select-font,.MuiInputBase-root, .MuiFormLabel-root {
            color: white;
        }
        .select-list{
            background: none;
            color: white;
        }
        .MuiSwitch-colorPrimary.Mui-checked {
            color: red;
        }
        .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
            background-color:red;
        }
        .MuiSwitch-colorPrimary.Mui-unchecked {
            color: white;
        }
        .MuiSwitch-colorPrimary.Mui-unchecked + .MuiSwitch-track {
            background-color: white;
        }
       
        .chipStyle {
            color: red;
            border: 1px solid red;
            background: rgb(60,60,60);
        }
        .chipSelected {
            background: red;
            color: white;
        }
        .weekDays-selected {
            background: red;
            color: white;
        }
        .weekDays {
            color: rgba(255, 0, 0, 0.4);
            border: 1px solid red;
            background: rgb(60,60,60);
        }
    }
    .card, .card-body {
        background: rgb(60,60,60);
        color: white;
    }
    .rct-block-content{
        background: rgba(49,49,50,.95);
        .button-scratch{
            background: none;
            color: red;
        }
    }
    .applyFilter{
        background: red;
        color: rgba(49,49,50,.95);
        border: 2px solid rgba(49,49,50,.95);
        }
    .scheduleDone{
        background: rgba(49,49,50,.95);
        color: red;
        border: 2px solid red;
    }
    .scheduleDone:hover, .scheduleDone:focus {
        background: red;
        color: #ffffff;
        border: 2px solid red;
    }
    .scheduleDone:active{
        background-color:red;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, .14 ), 0px 3px 14px 2px rgba(0, 0, 0, .12);
    }
}

.activity-template-theme-support {
  .MuiDialog-paperFullWidth{
        width: calc(100% - 32px);
    }
    .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 32px);
    }
    .MuiDialog-paper {
        margin: 16px;
        position: relative;
        overflow-y: clip;
    }
    .MuiPaper-root, .template-header, .template-actions, .MuiDialogActions-root {
        background-color: var(--screenContentBackgroundColor);
        .filter-button-cancel {
            color: var(--secondaryButtonTitleColor);
            border: 2px solid var(--secondaryButtonTitleColor);
            background-color:var(--secondaryButtonNormalStateColor);
        }
        .filter-button-apply {
            background-color: var(--primaryButtonNormalStateColor);
            color: var(--buttonTitleColor);
            border: 2px solid var(--primaryButtonNormalStateColor);
            &:hover {
            background-color: var(--primaryButtonNormalStateColor);
            }
        }
        .MuiButton-contained.Mui-disabled {
            background-color: var(--primaryButtonNormalStateColor);
            color: var(--buttonTitleColor);
        }
        .primary-button {
            background-color: var(--primaryButtonNormalStateColor);
            color: var(--buttonTitleColor) !important;
            border: 2px solid var(--primaryButtonNormalStateColor);
        }
        .listItemTitle{
            color: var(--headlineTextColor);
        }
        .activityPreviewBackbutton{
            background-color:var(--primaryButtonNormalStateColor);//var(--buttonTitleColor);
            color:var(--buttonTitleColor)
        }
        .backbutton{
            background-color:var(--primaryButtonNormalStateColor);//var(--buttonTitleColor);
            color:var(--buttonTitleColor);
            border: 2px solid var(--buttonTitleColor);
            .MuiSvgIcon-root{
                color: var(--buttonTitleColor);
            }
        }
        a {
            color: var(--primaryButtonNormalStateColor);
        }
        .helpbutton {
            background: var(--primaryButtonNormalStateColor);
            color: var(--buttonTitleColor); //var(--buttonTitleColor);
            border: 0px solid var(--buttonTitleColor) //var(--buttonTitleColor);
        }
        .backbutton:hover, .backbutton:focus{
            background: var(--buttonTitleColor);
            color: var(--primaryButtonNormalStateColor);
            border: 2px solid var(--primaryButtonNormalStateColor);
            .MuiSvgIcon-root{
                color: var(--primaryButtonNormalStateColor);
            } 
        }
        .activityPreviewBackbutton:hover, .activityPreviewBackbutton:focus{
            background: var(--buttonTitleColor);
            color: var(--primaryButtonNormalStateColor);
            border: 2px solid var(--primaryButtonNormalStateColor);
            .MuiSvgIcon-root{
                color: var(--primaryButtonNormalStateColor);
            } 
        }
        .helpbutton:hover, .helpbutton:focus {
            background: var(--primaryButtonNormalStateColor);
            color: var(--buttonTitleColor); //var(--buttonTitleColor);
            border: 0px solid var(--buttonTitleColor) //var(--buttonTitleColor);
        }
        .helpbutton:active{
           background: var(--primaryButtonNormalStateColor);
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, .14 ), 0px 3px 14px 2px rgba(0, 0, 0, .12);
        }
        .backbutton:active{
            background: var(--primaryButtonNormalStateColor);
            color:var(--buttonTitleColor);
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, .14 ), 0px 3px 14px 2px rgba(0, 0, 0, .12);
            .MuiSvgIcon-root{
                color:var(--buttonTitleColor);
            } 
        }
    }
    .filter-button-cancel {
        color: var(--secondaryButtonTitleColor);
        border: 2px solid var(--secondaryButtonTitleColor);
        background-color:var(--secondaryButtonNormalStateColor);
    }
    .filter-button-apply {
        background-color: var(--primaryButtonNormalStateColor);
        color: var(--buttonTitleColor);
        border: 2px solid var(--primaryButtonNormalStateColor);
        &:hover {
        background-color: var(--primaryButtonNormalStateColor);
        }
    }
    .template-content, .MuiDialogContent-root {
        background-color: var(--screenContentBackgroundColor);
        .filter-options-title {
            color: var(--headlineTextColor);
        }
        .steptitle, .listItemTitle{
            color: var(--headlineTextColor);
        }
        .detailslistItem {
            color: var(--primaryButtonNormalStateColor);
        }
        p {
            color: var(--headlineTextColor);
        }
        .MuiFormHelperText-root {
            color: var(--errorMessage);
        }
        h2 {
            color: var(--headlineTextColor); 
        }
        .rct-picker {
            .MuiSvgIcon-root {
                color: var(--primaryButtonNormalStateColor);
            }
        }        
        .btn-warning {
            color: var(--buttonTitleColor) !important;
            background: var(--primaryButtonNormalStateColor) !important;
            border-color: var(--primaryButtonNormalStateColor) !important;
            border: 0px solid var(--primaryButtonNormalStateColor);
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.8);
            min-width: 100px;
            min-height: 2.8rem !important;
            padding: 0.6rem 2rem;
        }
        .btn-grey {
            color: var(--alternativeSecondaryButtonTitleColor);
            background: var(--alternativeSecondaryButtonNormalStateColor);
            border-color: var(--alternativeSecondaryButtonNormalStateColor);
            min-width: 100px;
            min-height: 2.8rem !important;
            padding: 0.6rem 2rem;
        }
        .chip-array {
            .MuiChip-root {
                background: var(--loginScreenBackgroundColor);
                color: var(--primaryButtonNormalStateColor);
                border: 2px solid var(--primaryButtonNormalStateColor);
            }
            .MuiChip-clickable:hover, .MuiChip-clickable:focus {
                background-color:var(--loginScreenBackgroundColor);
            }
        }
        .chip-array-selected {
            .MuiChip-root {
                background:  var(--buttonTitleColor);
                color: var(--primaryButtonNormalStateColor);
                border: 2px solid var(--primaryButtonNormalStateColor);
            }
            .MuiChip-clickable:hover, .MuiChip-clickable:focus {
                background-color:var(--buttonTitleColor);
                border: 2px solid var(--primaryButtonNormalStateColor);
            }
        }
        .activityPreviewCloseButton {
            color: var(--primaryButtonNormalStateColor);
            border: 1px solid var(--buttonTitleColor);
            background-color: var(--buttonTitleColor);
        }
        .activityPreviewCloseButton:hover {
            background-color: var(--buttonTitleColor);
            color:var(--primaryButtonNormalStateColor);
            border: 1px solid var(--buttonTitleColor);
        }
        .menuEditItem, .select-font,.MuiInputBase-root, .MuiFormLabel-root {
            color: var(--headlineTextColor);
        }
        .select-list{
            background: none;
            color: var(--headlineTextColor);
        }
        .MuiSwitch-colorPrimary.Mui-checked {
            color: var(--primaryButtonNormalStateColor);
        }
        .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
            background-color:var(--primaryButtonNormalStateColor);
        }
        .MuiSwitch-colorPrimary.Mui-unchecked {
            color: #000000;
        }
        .MuiSwitch-colorPrimary.Mui-unchecked + .MuiSwitch-track {
            background-color: white;
        }
        .chipStyle {
            color: var(--secondaryButtonTitleColor);
            border: 2px solid var(--secondaryButtonTitleColor);
            background-color:var(--secondaryButtonNormalStateColor);
            .bg-danger{
                color: #fff;
            }
        }
        .chipSelected {
            background-color: var(--primaryButtonNormalStateColor);
            color: var(--buttonTitleColor);
            border: 2px solid var(--primaryButtonNormalStateColor);
            .MuiChip-avatar {
                color: var(--buttonTitleColor);
            }
        }
        .weekDays-selected {
            background:var(--primaryButtonNormalStateColor);
            color:var(--buttonTitleColor);
            border: 2px solid var(--primaryButtonNormalStateColor);
        }
        .weekDays {
            color: var(--secondaryButtonTitleColor);
            border: 2px solid var(--secondaryButtonTitleColor);
            background-color:var(--secondaryButtonNormalStateColor);
        }
    }
    .card, .card-body {
        background: var(--screenContentBackgroundColor);
        color: var(--subtextColor);
        .card-title {
          color : var(--headlineTextColor);
        }
    }
    .rct-block-content{
        background: var(--buttonTitleColor);
        .button-scratch{
            background: none;
            color: var(--buttonTitleColor);
        }
    }
    .applyFilter{
        background: var(--primaryButtonNormalStateColor);
        color: var(--buttonTitleColor);
        border: 2px solid var(--primaryButtonNormalStateColor);
        &:hover {
          background-color: var(--primaryButtonNormalStateColor);
          opacity: 0.7;
        }
    }
    .scheduleDone{
        color: var(--secondaryButtonTitleColor);
        border: 2px solid var(--secondaryButtonTitleColor);
        background-color:var(--secondaryButtonNormalStateColor);
    }
    .scheduleDone:hover, .scheduleDone:focus {
        background: var(--primaryButtonNormalStateColor);
        color: var(--buttonTitleColor);
        border: 0px solid var(--buttonTitleColor);
    }
    .scheduleDone:active{
        background: var(--buttonTitleColor);
        color: var(--primaryButtonNormalStateColor);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
        
    }
    .sweet-alert {
        color: var(--primaryButtonNormalStateColor);
        background-color: var(--screenContentBackgroundColor) !important;
        box-shadow: 0 1px 5px 1px var(--primaryButtonNormalStateColor)  !important;
        border: 1px solid var(--primaryButtonNormalStateColor);
        .sweet-alert-text {
            color: var(--primaryButtonNormalStateColor) !important;
        }
        h2 {
          color: var(--headlineTextColor) !important;
         }
        .btn-warning, .btn-info {
          color:var(--buttonTitleColor) !important;
          background: var(--alertButtonsColor);
          border:0px solid rgb(238, 162, 54);
          box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 1px inset, rgba(238, 162, 54,0.6) 0px 0px 8px;
          min-width: 100px;
          min-height: 2.8rem !important;
          padding: 0.6rem 2rem;
        }
        .btn-warning:not(:disabled):not(.disabled):active {
          color:var(--buttonTitleColor) !important;
          background-color: var(--alertButtonsColor);
        }
        .btn-default:not(:disabled):not(.disabled):active {
            color:var(--buttonTitleColor) !important;
            background-color: var(--alertButtonsColor);
        }
        .btn-default {
            color: var(--alternativeSecondaryButtonTitleColor);
            background: var(--alternativeSecondaryButtonNormalStateColor);
            border-color: var(--alternativeSecondaryButtonNormalStateColor);
            min-width: 100px;
            min-height: 2.8rem !important;
            padding: 0.6rem 2rem;
        }
      }
}

.activity-template-theme2 {
    .MuiDialog-paperFullWidth{
        width: calc(100% - 16px);
    }
    .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 16px);
    }
    .MuiDialog-paper {
        margin: 8px;
        position: relative;
        overflow-y: clip;
    }
    .MuiPaper-root, .template-header, .template-actions, .MuiDialogActions-root {
        background-color: #F8F8F8;
        .listItemTitle{
            color: #134266;
        }
        .helpbutton {
            background: #FFFFFF;
            color: #134266;
            border: 0px solid #134266;
        }
        .backbutton{
            background-color:#FFFFFF;
            color: #134266;
            border: 2px solid #134266;
            .MuiSvgIcon-root{
                color: #134266;
            }
        }
        .activityPreviewBackbutton{
            background-color:#FFFFFF;
            color: #134266;
        }
        .backbutton:hover, .backbutton:focus, .helpbutton:hover, .helpbutton:focus, .activityPreviewBackbutton:hover {
            background: #134266;
            color: #FFFFFF;
            border: 2px solid #FFFFFF;
            .MuiSvgIcon-root{
                color: #FFFFFF;
            }
        }
        .backbutton:active, .backbutton:active, .helpbutton:active{
            background: #134266;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
            
        }
    }
    .template-content, .MuiDialogContent-root {
        background-color: #F8F8F8;
        .steptitle, .listItemTitle{
            color: #103958;
        }
        .detailslistItem {
            color: #103958;
        }
        p {
            color: #51727F;
        }
        .chip-array {
            .MuiChip-root {
                background: #FFFFFF;
                color: #134266;
                border: 2px solid #134266;
            }
            .MuiChip-clickable:hover, .MuiChip-clickable:focus {
                background-color:#FFFFFF;
                border: 2px solid #134266;
            }
        }
        .chip-array-selected {
            .MuiChip-root {
                background:  #134266;
                color: #FFFFFF;
                border: 2px solid #134266;
            }
            .MuiChip-clickable:hover, .MuiChip-clickable:focus {
                background-color:#134266;
            }
        }
        .activityPreviewCloseButton {
            color: #134266;
            border: 1px solid #134266;
            background-color: #FFFFFF;
        }
        .activityPreviewCloseButton:hover {
            background-color: #134266;
            color:#FFFFFF;
            border: 1px solid #FFFFFF;
        }
        .menuEditItem, .select-font,.MuiInputBase-root, .MuiFormLabel-root {
            color: #103958;
        }
        .select-list{
            background: none;
            color: #103958;
        }
        .MuiSwitch-colorPrimary.Mui-checked {
            color: #134266;
        }
        .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
            background-color:#134266;
        }
        .MuiSwitch-colorPrimary.Mui-unchecked {
            color: #FFFFFF;
        }
        .MuiSwitch-colorPrimary.Mui-unchecked + .MuiSwitch-track {
            background-color: white;
        }
        .chipStyle {
            color: #134266;
            border: 1px solid #134266;
            background: #FFFFFF;
        }
        .chipSelected {
            background: #134266;
            color: #FFFFFF;
        }
        .weekDays-selected {
            background: #134266;
            color: #FFFFFF;
        }
        .weekDays {
            color: rgba(19, 66, 102, 0.4);
            border: 1px solid #134266;
            background: #FFFFFF;
        }
    }
    .card, .card-body {
        background: #FFFFFF;
        color: #134266;
    }
    .rct-block-content{
        background: #FFFFFF;
        .button-scratch{
            background: none;
            color: #134266;
        }
    }
   .applyFilter{
    background: #134266;
    color: #FFFFFF;
    border: 2px solid #FFFFFF;   
    }
    .scheduleDone{
        background: #FFFFFF;
        color: #134266;
        border: 2px solid #134266;
    }
    .scheduleDone:hover, .scheduleDone:focus {
        background: #134266;
        color: #FFFFFF;
        border: 2px solid #FFFFFF;
    }
    .scheduleDone:active {
        background: #134266;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
        
    }
}

.activity-template-theme3 {
    .MuiDialog-paperFullWidth{
        width: calc(100% - 16px);
    }
    .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 16px);
    }
    .MuiDialog-paper {
        margin: 8px;
        position: relative;
        overflow-y: clip;
    }
    .MuiPaper-root, .template-header, .template-actions, .MuiDialogActions-root {
        background-color: #F8F8F8;
        .listItemTitle{
            color: #134266;
        }
        .helpbutton {
            background: #FFFFFF;
            color: #134266;
            border: 0px solid #134266;
        }
        .backbutton{
            background-color:#FFFFFF;
            color: #134266;
            border: 2px solid #134266;
            .MuiSvgIcon-root{
                color: #134266;
            }
        }
        .activityPreviewBackbutton{
            background-color:#FFFFFF;
            color: #134266;
        }
        .activityPreviewBackbutton:hover, .backbutton:hover, .backbutton:focus, .helpbutton:hover, .helpbutton:focus {
            background: #134266;
            color: #FFFFFF;
            border: 2px solid #FFFFFF;
            .MuiSvgIcon-root{
                color: #FFFFFF;
            }
        }
        .backbutton:active, .helpbutton:active {
            background: #134266;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
            .MuiSvgIcon-root{
                color: #FFFFFF;
            }
            
        }
    }
    .template-content, .MuiDialogContent-root {
        background-color: #F8F8F8;
        .steptitle, .listItemTitle{
            color: #103958;
        }
        .detailslistItem {
            color: #103958;
        }
        p {
            color: #51727F;
        }
        .chip-array {
            .MuiChip-root {
                background: #FFFFFF;
                color: #134266;
                border: 2px solid #134266;
            }
            .MuiChip-clickable:hover, .MuiChip-clickable:focus {
                background-color:#FFFFFF;
                border: 2px solid #134266;
            }
        }
        .chip-array-selected {
            .MuiChip-root {
                background:  #134266;
                color: #FFFFFF;
                border: 2px solid #134266;
            }
            .MuiChip-clickable:hover, .MuiChip-clickable:focus {
                background-color:#134266;
                border: 2px solid #134266;
            }
        }
        .activityPreviewCloseButton {
            color: #134266;
            border: 1px solid #134266;
            background-color: #FFFFFF;
        }
        .activityPreviewCloseButton:hover {
            background-color: #134266;
            color:#FFFFFF;
            border: 1px solid #FFFFFF;
        }
        .menuEditItem, .select-font,.MuiInputBase-root, .MuiFormLabel-root {
            color: #103958;
        }
        .select-list{
            background: none;
            color: #103958;
        }
        .MuiSwitch-colorPrimary.Mui-checked {
            color: #134266;
        }
        .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
            background-color:#134266;
        }
        .MuiSwitch-colorPrimary.Mui-unchecked {
            color: #FFFFFF;
        }
        .MuiSwitch-colorPrimary.Mui-unchecked + .MuiSwitch-track {
            background-color: white;
        }
        .chipStyle {
            color: #134266;
            border: 1px solid #134266;
            background: #FFFFFF
        }
        .chipSelected {
            background: #134266;
            color: #FFFFFF;
        }
        .weekDays-selected {
            background: #134266;
            color: #FFFFFF;
        }
        .weekDays {
            color: rgba(19, 66, 102, 0.4);
            border: 1px solid #134266;
            background: #FFFFFF
        }
    }
    .card, .card-body {
        background: #134266;
        color: #FFFFFF;
    }
    .rct-block-content{
        background: #FFFFFF;
        .button-scratch{
            background: none;
            color: #134266;
        }
    }
    .applyFilter{
        background: #134266;
        color: #FFFFFF;
        border: 2px solid #FFFFFF;;
        }
    .scheduleDone{
        background: #FFFFFF;
        color: #134266;
        border: 2px solid #134266;
    }
    .scheduleDone:hover, .scheduleDone:focus {
        background: #134266;
        color: #FFFFFF;
        border: 2px solid #FFFFFF;;
    }
    .scheduleDone:active{
        background: #134266;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
        
    }
}
.activity-template-theme5 {
    .MuiDialog-paperFullWidth{
        width: calc(100% - 16px);
    }
    .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 16px);
    }
    .MuiDialog-paper {
        margin: 8px;
        position: relative;
        overflow-y: clip;
    }
    .MuiPaper-root, .template-header, .template-actions, .MuiDialogActions-root {
        background-color: #E0E0E0;
        .listItemTitle{
            color: #0F594E;
        }
        .helpbutton {
            background: #FFFFFF;
            color: #0F594E; 
            border: 0px solid #0F594E;
        }
        .backbutton{
            background-color:#FFFFFF;
            color: #0F594E;
            border: 2px solid #0F594E;
            .MuiSvgIcon-root{
                color: #0F594E;
            }
        }
        .activityPreviewBackbutton{
            background-color:#FFFFFF;
            color: #0F594E;
            border: 1px solid #0F594E;
        }
        .activityPreviewBackbutton:hover, .backbutton:hover, .backbutton:focus, .helpbutton:hover, .helpbutton:focus {
            background: #0F594E;
            color: #FFFFFF;
            border: 2px solid #FFFFFF;
            .MuiSvgIcon-root{
                color: #FFFFFF;
            }
        }
        .backbutton:active, .helpbutton:active {
            background: #0F594E;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
            .MuiSvgIcon-root{
                color: #FFFFFF;
            }
        }
    }
    .template-content, .MuiDialogContent-root {
        background-color: #E0E0E0;
        .steptitle, .listItemTitle{
            color: #0F594E;
        }
        .detailslistItem {
            color: #0F594E;
        }
        p {
            color: #575757;
        }
        .chip-array {
            .MuiChip-root {
                background: #FFFFFF;
                color: #0F594E;
                border: 2px solid #0F594E;
            }
            .MuiChip-clickable:hover, .MuiChip-clickable:focus {
                background-color:#FFFFFF;
                border: 2px solid #0F594E;
            }
        }
        .chip-array-selected {
            .MuiChip-root {
                background:  #0F594E;
                color: #FFFFFF;
                border: 2px solid #0F594E;
            }
            .MuiChip-clickable:hover, .MuiChip-clickable:focus {
                background-color:#0F594E;
                border: 2px solid #0F594E;
            }
        }
        .activityPreviewCloseButton {
            color: #0f594e;
            border: 1px solid #0f594e;
            background-color: #FFFFFF;
        }
        .activityPreviewCloseButton:hover {
            background-color: #0f594e;
            color:#FFFFFF;
            border: 1px solid #FFFFFF;
        }
        .menuEditItem, .select-font,.MuiInputBase-root, .MuiFormLabel-root {
            color: #0F594E;
        }
        .select-list{
            background: none;
            color: #0F594E;
        }
        .MuiSwitch-colorPrimary.Mui-checked {
            color: #0F594E;
        }
        .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
            background-color:#0F594E;
        }
        .MuiSwitch-colorPrimary.Mui-unchecked {
            color: #FFFFFF;
        }
        .MuiSwitch-colorPrimary.Mui-unchecked + .MuiSwitch-track {
            background-color: white;
        }
        .chipStyle {
            color: #0F594E;
            border: 1px solid #0F594E;
            background: #FFFFFF
        }
        .chipSelected {
            background: #0F594E;
            color: #FFFFFF;
        }
        .weekDays-selected {
            background: #0F594E;
            color: #FFFFFF;
        }
        .weekDays {
            color: rgba(19, 66, 102, 0.4);
            border: 1px solid #0F594E;
            background: #FFFFFF
        }
    }
    .card, .card-body {
        background: #FFFFFF;
        color: #0F594E;
    }
    .rct-block-content{
        background: #FFFFFF;
        .button-scratch{
            background: none;
            color: #0F594E;
        }
    }
    .applyFilter{
        background: #0F594E;
        color: #FFFFFF;
        border: 2px solid #FFFFFF;;
        }
    .scheduleDone{
        background: #FFFFFF;
        color: #0F594E;
        border: 2px solid #0F594E;
    }
    .scheduleDone:hover, .scheduleDone:focus {
        background: #0F594E;
        color: #FFFFFF;
        border: 2px solid #FFFFFF;;
    }
    .scheduleDone:active{
        background: #0F594E;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
        
    }
}

.chart {
    .MuiDialog-paperFullWidth{
        width: calc(100% - 0px);
    }
    .MuiDialog-paper {
        margin: 0px;
        position: relative;
        overflow-y: clip;
    }
    .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 0px);
    }
}
.cam-container {
    display: grid;
}


.rct-session-wrapper-ls {
  .session-header {
      a {
       color: gray;
      }
  }
  
}
.session-wrapper-theme-support {
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  position: fixed;
  right: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--loginScreenBackgroundColor);
  header {
    height: 250px; //150px;
    background: none !important;
    justify-content: space-around;
    box-shadow: none;
  }
  .session-header {
      a {
        color: var(--primaryButtonNormalStateColor) ;
      }
  }
  .session-inner-wrapper {
    padding-bottom: 1.875rem;
    min-height: calc(100vh - 130px);
    overflow-x: hidden;
    .session-body {
      background-color:var(--screenContentBackgroundColor);
      border-radius: 5px;
      padding: 2rem 6.25rem;
      .login-screen-button {
        background-color: var(--primaryButtonNormalStateColor);
        border:1px solid var(--primaryButtonNormalStateColor);
        color: var(--buttonTitleColor);
        &:hover , &:focus {
          background-color: var(--primaryButtonNormalStateColor);
          opacity: 0.9;
        }
      }
      .Mui-disabled {
        color: var(--buttonTitleDisabledColor);
        box-shadow: none;
        background-color:var(--buttonDisabledStateColor);
        border: 1px solid var(--buttonDisabledStateColor);
      }
       .base-font-color {
          color: var(--headlineTextColor) !important;
          font-size: 18px;
       }
       a {
          color: var(--headlineTextColor);
          text-decoration: underline;
       }
       p {
          color: var(--headlineTextColor) !important; 
          font-size: 18px;
       }
       h2 {
          color: var(--headlineTextColor); 
       }
    }
  }
  .sweet-alert {
    color: var(--primaryButtonNormalStateColor);
    background-color:var(--screenContentBackgroundColor) !important;
    box-shadow: 0 1px 5px 1px var(--primaryButtonNormalStateColor)  !important;
    border: 0px solid var(--alternativeSecondaryButtonNormalStateColor);
    .sweet-alert-text {
        color: var(--primaryButtonNormalStateColor) !important;
    }
    .btn-warning, .btn-info {
      color:var(--buttonTitleColor) !important;
      background: var(--alertButtonsColor);
      border:0px solid rgb(238, 162, 54);
      box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 1px inset, rgba(238, 162, 54,0.6) 0px 0px 8px;
    }
  }
}
.session-wrapper-theme-support-des {
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  position: fixed;
  right: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--loginScreenBackgroundColor);
  header {
    height: 173px; //150px;
    background: none !important;
    justify-content: space-around;
    box-shadow: none;
  }
  .session-header {
      a {
        color: var(--primaryButtonNormalStateColor) ;
      }
  }
  .session-inner-wrapper {
    padding-bottom: 1.875rem;
    min-height: calc(100vh - 130px);
    overflow-x: hidden;
    .session-body {
      background-color:var(--screenContentBackgroundColor);
      border-radius: 5px;
      padding: 2rem 6.25rem;
      .login-screen-button {
        background-color: var(--primaryButtonNormalStateColor);
        border:1px solid var(--primaryButtonNormalStateColor);
        color: var(--buttonTitleColor);
        &:hover , &:focus {
          background-color: var(--primaryButtonNormalStateColor);
          opacity: 0.9;
        }
      }
      .Mui-disabled {
        color: var(--buttonTitleDisabledColor);
        box-shadow: none;
        background-color:var(--buttonDisabledStateColor);
        border: 1px solid var(--buttonDisabledStateColor);
      }
       .base-font-color {
          color: var(--headlineTextColor) !important;
          font-size: 18px;
       }
       a {
          color: var(--headlineTextColor);
       }
       p {
          color: var(--headlineTextColor) !important; 
          font-size: 18px;
       }
       h2 {
          color: var(--headlineTextColor); 
       }
    }
  }
}
.rct-session-wrapper-theme1 {
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #FFFFFF;
    header {
      height: 210px; //150px;
      background: none !important;
      justify-content: space-around;
      box-shadow: none;
    }
    .session-header {
        a {
         color: #5F249F;
        }
    }
    .session-inner-wrapper {
      .session-body {
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 2rem 6.25rem;
        .login-screen-button { 
            background-color: #5F249F;
            border:1px solid #5F249F;
            color: #ffffff;
            &:hover , &:focus {
              background-color: #5F249F;
            }
         }
         .base-font-color {
            color: #5F249F !important;
            font-size: 18px;
         }
         a {
            color: #5F249F;
         }
         p {
            color: #5F249F !important; 
            font-size: 18px;
         }
         h2 {
            color: #5F249F; 
         }
      }
    }
  }
  .rct-session-wrapper-theme2 {
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #F8F8F8;
    header {
      height: 210px; //150px;
      background: none !important;
      justify-content: space-around;
      box-shadow: none;
    }
    .session-header {
        a {
         color: rgb(44, 43, 43);
        }
    }
    .session-inner-wrapper {
      .session-body {
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 2rem 6.25rem;
        box-shadow:  0 1px 15px 1px rgba(69,65,78,0.16);
        color: #134266;
        .base-font-color {
            color: #51727F !important;
            font-size: 18px;
        }
        .login-screen-button {
        color: #fff; 
        background-color: #134266;
        border:1px solid #134266;
        &:hover , &:focus {
            background-color: #103958;
         }
        }
        a {
            color: #134266;
        }
        h2 {
            color: #134266;
         }
         p {
            color: #134266;
            font-size: 18px;
         }
      }
    }
  }
  .rct-session-wrapper-theme3 {
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #F8F8F8;
    header {
      height: 210px; //150px;
      background: none !important;
      justify-content: space-around;
      box-shadow: none;
    }
    .session-header {
        a {
         color: rgb(44, 43, 43);
        }
    }
    .session-inner-wrapper {
      .session-body {
        color: #134266;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 2rem 6.25rem;
        box-shadow:  0 1px 15px 1px rgba(69,65,78,0.16);
        .base-font-color {
            color: #51727F !important;
            font-size: 18px;
        }
        .login-screen-button {
        color: #fff; 
        background-color: #134266;
        border:1px solid #134266;
        &:hover , &:focus {
            background-color: #103958;
         }
        }
        a {
            color: #134266;
          }
         h2 {
            color: #134266;
         }
         p {
            color: #134266;
            font-size: 18px;
         }
          .border {
            border: 1px solid #134266 !important; 
           }
      }
    }
  }

  .rct-session-wrapper-theme5 {
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #0F594E;
    header {
      height: 210px; //150px;
      background: none !important;
      justify-content: space-around;
      box-shadow: none;
    }
    .session-header {
        a {
         color:#f5f5f5;
        }
    }
    .session-inner-wrapper {
      .session-body {
        color: #0f594e;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 2rem 6.25rem;
      .base-font-color {
        color: #0f594e !important;
        font-size: 18px;
      }
      .login-screen-button {
        color: #0F594E; 
        background: #ADCE65;
        border:1px solid #7A9B32;
        &:hover , &:focus {
          background-color: #7A9B32;
        }
      }
      a {
        color: #0f594e;
      }
      p {
        color: #0f594e !important;
        font-size: 18px; 
      }
      h2 {
        color: #0f594e;
      }
      .border {
        border: 1px solid #0F594E !important; 
       }
      }
    }
  }
 .rct-session-wrapper {
  .session-inner-wrapper {
    .login-screen-button {
      color: #ffffff; 
      background-color: #CB251E ;
      border:2px solid #CB251E;
      &:hover , &:focus {
        background-color: #980000;
      }
    }
   }
 }

 .rct-session-wrapper-support {
  .session-inner-wrapper {
    .login-screen-button {
      color: #ffffff; 
      background-color: #CB251E ;
      border:2px solid #CB251E;
      &:hover , &:focus {
        background-color: #980000;
      }
    }
   }
 }
  @media (max-width: 1199px) and (min-width: 320px) {
    .session-wrapper-theme-support,.session-wrapper-theme-support-des {
      .session-inner-wrapper {
        .session-body {
          padding: 2rem .5rem;
        }
      }
    } 
    .object-editor {
      .rct-session-wrapper-theme-support{
        .session-inner-wrapper {
          .session-body {
            padding: 2rem 0rem;
          }
        }
      }
    }
    .rct-session-wrapper-theme1 {
        .session-inner-wrapper {
          .session-body {
            padding: 2rem .5rem;
          }
        }
      } 
      .rct-session-wrapper-theme2 {
        .session-inner-wrapper {
          .session-body {
            padding: 2rem .5rem;
          }
        }
      } 
      .rct-session-wrapper-theme3 {
        .session-inner-wrapper {
          .session-body {
            padding: 2rem .5rem;
          }
        }
      } 
      .rct-session-wrapper-theme5 {
        .session-inner-wrapper {
          .session-body {
            padding: 2rem .5rem;
          }
        }
      } 
  }
  @media (min-width: 1201px ) {
    .session-wrapper-theme-support, .session-wrapper-theme-support-des {
      .session-body{
        iframe {
          width: 100%;
          height: calc(100vh - 350px);
        }
      }
    }
  }
  
  @media (max-width: 1200px ) {
    .session-wrapper-theme-support, .session-wrapper-theme-support-des {
      .session-body{
        iframe {
          width: 100%;
          height: calc(100vh - 390px);
        }
      }
    }
  }
  @media (max-width: 767px) {
    .session-wrapper-theme-support, .session-wrapper-theme-support-des {
      .session-body{
        iframe {
          width: 100%;
          height: calc(100vh -  350px);
        }
      }
    }
  }
/*=========== Obejct Editor Style =========*/
.object-editor {
  .assign-activity{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    button:disabled {
      opacity: 0.6;
    }
  }
  @media (max-width: 767px) {
    .assign-activity{
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }
  .session-wrapper {
    background-color: rgba(49, 49, 50, 1);
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .activityPreviewBackbutton {
      background: red;
      box-shadow: 0px 2px 12px rgba(255, 255, 255, 0.1);
      .MuiSvgIcon-root {
        color: white;
      }
    }
    .backbutton {
      background: red;
      box-shadow: 0px 2px 12px rgba(255, 255, 255, 0.1);
      border: 0px solid red;
      .MuiSvgIcon-root {
        color: white;
      }
    }
    .loading-dots { 
      &--dot {
        background-color: red;
      }
    }
    .scheduleDone,
    .helpbutton {
      background: rgba(49, 49, 50, 0.95);
      color: red;
      border: 2px solid red;
    }
    .headerButton {
      background: rgba(255, 0, 0, 1);
      color: white;
    }
    .linkButton {
      color: white;
    }
    .session-inner-wrapper {
      // transform: translate3d(0%, 10%, 0);
      padding-bottom: 1.875rem;
      min-height: calc(100vh - 130px);
      overflow-x: hidden;
    }
    .headerButton:hover {
      background: rgba(255, 0, 0, 0.9);
    }
    .headerButton:focus {
      background: rgba(255, 0, 0, 1);
    }
    .activityPreviewBackbutton:hover,
    .scheduleDone:hover,
    .helpbutton:hover,
    .backbutton:hover,
    .scheduleDone:focus,
    .helpbutton:focus,
    .backbutton:focus {
      background: red;
      color: #ffffff;
      border: 2px solid rgba(49, 49, 50, 0.95);
    }
    .scheduleDone:active,
    .helpbutton:active,
    .backbutton:active {
      background: red;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    }
  }
  .session-wrapper-ls {
    background-color: rgba(255, 255, 255, 1);
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .assign-activity-search-header {
      min-height: 60px !important;
      padding-bottom: 0px !important;
    }
    .session-inner-wrapper {
      // transform: translate3d(0%, 10%, 0);
      padding-bottom: 1.875rem;
      min-height: calc(100vh - 130px);
      overflow-x: hidden;
      .session-body {
        .session-head {
          @media (min-width: 576px) {
            .icon-font-size {
              font-size: 2rem;
            }
          }
          @media (max-width: 575px) and (min-width: 450px) {
            .icon-font-size {
              font-size: 2rem;
            }
          }
          @media (max-width: 450px) {
            .icon-font-size {
              font-size: 1.8rem;
            }
          }
          .rct-block-content {
            background: white;
            a {
              text-decoration: none;
              &:hover {
                color: #008C95;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  .assign-activities-rct-session {
    overflow-y: hidden !important;
  }
  .session-wrapper-theme-support {
    background-color: var(--screenContentBackgroundColor) !important;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .backbutton {
      background: var(--primaryButtonNormalStateColor); //var(--buttonTitleColor);
      box-shadow: 0px 2px 12px rgba(255, 255, 255, 0.1);
      border: 2px solid var(--buttonTitleColor);
      color: var(--buttonTitleColor);
      .MuiSvgIcon-root {
        color: var(--buttonTitleColor); //var(--primaryButtonNormalStateColor);
      }
    }
    .helpbutton {
      background: var(--primaryButtonNormalStateColor); //var(--buttonTitleColor);
      color: var(--buttonTitleColor); //var(--primaryButtonNormalStateColor);
      border: 0px solid var(--primaryButtonNormalStateColor); //var(--buttonTitleColor);
    }
    .activities-not-filtered {
      background: var(--primaryButtonNormalStateColor); //var(--buttonTitleColor);
      color: var(--screenContentBackgroundColor); //var(--primaryButtonNormalStateColor);
      border: 2px solid var(--primaryButtonNormalStateColor); //var(--buttonTitleColor);
    }
    .activities-filtered {
        color: var(--secondaryButtonTitleColor);
        border: 2px solid var(--secondaryButtonTitleColor);
        background-color:var(--secondaryButtonNormalStateColor);
    }
    .scheduleDone {
      background: var(--buttonTitleColor); //var(--primaryButtonNormalStateColor);
      color: var(--primaryButtonNormalStateColor); //var(--buttonTitleColor);
      border: 2px solid var(--primaryButtonNormalStateColor); //var(--buttonTitleColor);
    }
    .headerButton {
      background: var(--primaryButtonNormalStateColor);
      color: var(--buttonTitleColor);
    }
    .linkButton {
      color: var(--buttonTitleColor); // var(--primaryButtonNormalStateColor);
    }
    .headerButton:hover {
      background: var(--primaryButtonNormalStateColor);
    }
    .headerButton:focus {
      background: var(--primaryButtonNormalStateColor);
    }
    .scheduleDone:hover,
    .scheduleDone:focus {
      background: var(--primaryButtonNormalStateColor);
      color: var(--buttonTitleColor);
      border: 0px solid var(--buttonTitleColor);
    }
    .helpbutton:hover,
    .backbutton:hover,
    .helpbutton:focus,
    .backbutton:focus {
      background: var(--primaryButtonNormalStateColor);
      color: var(--buttonTitleColor);
      border: 2px solid var(--buttonTitleColor);
      .MuiSvgIcon-root {
        color: var(--buttonTitleColor); //var(--primaryButtonNormalStateColor);
      }
    }
    .scheduleDone:active,
    .helpbutton:active,
    .backbutton:active {
      // background: var(--buttonTitleColor);
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
      background: var(--primaryButtonNormalStateColor); //var(--buttonTitleColor);
      color: var(--buttonTitleColor);
      border: 0px solid var(--buttonTitleColor);
      .MuiSvgIcon-root {
        color: var(--buttonTitleColor); //var(--primaryButtonNormalStateColor);
      }
    }
    
    .session-inner-wrapper {
      // transform: translate3d(0%, 10%, 0);
      padding-bottom: 1.875rem;
      min-height: calc(100vh - 130px);
      overflow-x: hidden;
    }
    .assign-activity-search-header {
      min-height: 60px !important;
      padding-bottom: 0px !important;
    }
  }
  .session-wrapper-theme2 {
    background-color: #f8f8f8;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .backbutton {
      background: #ffffff;
      box-shadow: 0px 2px 12px rgba(255, 255, 255, 0.1);
      border: 2px solid #134266;
      color: #134266;
      .MuiSvgIcon-root {
        color: #134266;
      }
    }
    .scheduleDone,
    .helpbutton {
      background: #ffffff;
      color: #134266;
      border: 2px solid #134266;
    }
    .headerButton {
      background: rgba(19, 66, 102, 1);
      color: #ffffff;
    }
    .linkButton {
      color: #ffffff;
    }
    .headerButton:hover {
      background: rgba(19, 66, 102, 0.9);
    }
    .headerButton:focus {
      background: rgba(19, 66, 102, 1);
    }
    .scheduleDone:hover,
    .helpbutton:hover,
    .backbutton:hover,
    .scheduleDone:focus,
    .helpbutton:focus,
    .backbutton:focus {
      background: #134266;
      color: #ffffff;
      border: 2px solid #ffffff;
      .MuiSvgIcon-root {
        color: #ffffff; //#5F249F;
      }
    }
    .scheduleDone:active,
    .helpbutton:active,
    .backbutton:active {
      background: #134266;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    }
    .session-inner-wrapper {
      // transform: translate3d(0%, 10%, 0);
      padding-bottom: 1.875rem;
      min-height: calc(100vh - 130px);
      overflow-x: hidden;
    }
  }
  .session-wrapper-theme5 {
    background-color: #e0e0e0;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .backbutton {
      background: #ffffff;
      box-shadow: 0px 2px 12px rgba(255, 255, 255, 0.1);
      border: 2px solid #0f594e;
      color: #0f594e;
      .MuiSvgIcon-root {
        color: #0f594e;
      }
    }
    .assignActivityHeading {
      color: #0f594e;
    }
    .user-details {
      color: #0f594e;
      text-align: center;
    }
    .scheduleDone,
    .helpbutton {
      background: #ffffff;
      color: #0f594e;
      border: 2px solid #0f594e;
    }
    .headerButton {
      background: rgba(15, 89, 78, 1);
      color: #ffffff;
    }
    .linkButton {
      color: #ffffff;
    }
    .headerButton:hover {
      background: rgba(15, 89, 78, 0.9);
    }
    .headerButton:focus {
      background: rgba(15, 89, 78, 1);
    }
    .scheduleDone:hover,
    .helpbutton:hover,
    .backbutton:hover,
    .scheduleDone:focus,
    .helpbutton:focus,
    .backbutton:focus {
      background: #0f594e;
      color: #ffffff;
      border: 2px solid #ffffff;
      .MuiSvgIcon-root {
        color: #ffffff; //#5F249F;
      }
    }

    .scheduleDone:active,
    .helpbutton:active,
    .backbutton:active {
      background: #0f594e;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    }
    .session-inner-wrapper {
      // transform: translate3d(0%, 10%, 0);
      padding-bottom: 1.875rem;
      min-height: calc(100vh - 130px);
      overflow-x: hidden;
    }
  }
  .session-header {
    background: none !important;
    box-shadow: none !important;
    > div {
      padding: 0;
    }
  }
  .session-logo {
    display: flex;
    align-items: center;
    i {
      font-size: 1.875rem; //30px;
      margin-right: 0.9375rem; //15px;
      color: $white;
    }
  }
  .has-wrapper {
    position: relative;
    .has-input {
      border: none;
      padding: 0.625rem 1.563rem;
    }
    .has-icon {
      position: absolute;
      right: 25px;
      top: 13px;
      font-size: 1.25rem; //20px;
    }
  }
  .btn-large {
    height: $input-lg-height;
  }
  .error-wrapper {
    @extend .session-wrapper;
    background: $info;
    background-image: none;
    overflow-x: hidden;
  }
  .error-body {
    h2 {
      font-weight: 900;
      text-shadow: 9px 10px 1px rgba(0, 0, 0, 0.08);
    }
  }
  .oops {
    font-size: 3.75rem; //60px;
  }
  .bold {
    font-size: 13.75rem; //220px;
  }
  .error-msg {
    font-size: 4.375rem; //70px;
  }
  .rct-session-wrapper {
    @extend .session-wrapper;
    .row-eq-height {
      align-items: center;
    }
    header {
      height: 100px; //150px;
      background: none !important;
      justify-content: space-around;
      box-shadow: none;
      .assignActivityHeading {
        color: white;
      }
      .user-details {
        color: white;
        text-align: center;
      }
    }
    .listItem {
      color: white;
    }
    .session-inner-wrapper {
      .session-body {
        background-color: rgba(49, 49, 50, 1);
        border-radius: $block-border-radius;
        padding: 2rem 6.25rem; //30px 100px;
        .session-head {
          .schedule-list-item-odd {
            background-color: #424040;
          }
          .primary-color {
            color: red;
          }
          .searchFieldIcon {
            .MuiSvgIcon-colorPrimary {
              color: white;
            }
          }
          .loading-dots { 
            &--dot {
              background-color: white;
            }
          }
          .calender-schedule {
            border: 1px solid red;
          }
          .month-header {
            background: red;
            color: white;
          }
          @media (min-width: 576px) {
            .icon-font-size {
              font-size: 2rem;
            }
          }
          @media (max-width: 575px) and (min-width: 450px) {
            .icon-font-size {
              font-size: 2rem;
            }
          }
          @media (max-width: 450px) {
            .icon-font-size {
              font-size: 1.8rem;
            }
          }
          .titleIcon, .add-new-dropdown {
            background: red;
          }
          .inputTitleErr {
            color: white;
            background: rgba(49, 49, 50, 1);
          }

          .inputTitle,
          .desciption {
            color: white;
            background: rgb(60, 60, 60);
            box-shadow: 0 1px 15px 1px rgb(0, 0, 0,);
            // .MuiInputBase-input{
            //   color: white;
            // }
            // background: rgb(60,60,60);
          }
          .optionsRow {
            background: rgba(255, 0, 0, 1);
            .MuiBadge-colorError {
              background-color: rgba(49, 49, 50, 1);
            }
          }
          .unselectBox {
            box-shadow: 0px 0px 0px #f2f2f2;
          }
          .tabtitle {
            color: white;
          }
          .tabtitle-selected {
            color: #241f1f;
        }
          .list-card {
            box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.4);
            background: rgba(49, 49, 50, 0.95);
            .scheduleTitle,
            .listItem,
            .small,
            .step-input-titleErr,
            .steptitle,
            .stepnote,
            .MuiIconButton-root,
            .text-muted {
              color: white;
            }
          }
          .list-card-drag {
            box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.4);
            background: rgba(49, 49, 50, 0.95);
            .scheduleTitle,
            .listItem,
            .small,
            .step-input-titleErr,
            .steptitle,
            .stepnote,
            .MuiIconButton-root,
            .text-muted {
              color: white;
            }
          }
          .rct-block-content {
            color: white;
            background: rgb(49, 49, 51);
            box-shadow: 0 1px 15px 1px rgb(0, 0, 0);
            .scheduleTitle,
            .listItem,
            .small,
            .step-input-titleErr,
            .steptitle,
            .stepnote,
            .MuiIconButton-root,
            .text-muted {
              color: white;
            }
            .buttonWithBorder {
              background: rgba(49, 49, 50, 0.95);
              color: red;
              border: 2px solid red;
            }
            a {
              text-decoration: none;
              &:hover {
                color: red;
                text-decoration: underline;
              }
            }
            .plusbutton,
            .button-scratch {
              background: none;
              color: red;
            }
            .addstep,
            .oe-text-primary {
              color: red;
            }
            .add-step-container {
              .MuiButton-root {
                text-decoration: none;
                background-color: rgba(49, 49, 50, 0.95);
                .MuiButton-label {
                  color: red;
                }
              }
              .MuiButton-root:hover {
                text-decoration: none;
                background-color: red;
                .MuiButton-label {
                  color: #ffffff;
                }
              }
              @media (hover: none) {
                .MuiButton-root:hover {
                  background-color: transparent;
                  .MuiButton-label {
                    color: red;
                  }
                }
              }
            }
            .oe-text-primary:hover,
            .buttonWithBorder:hover,
            .oe-text-primary:focus,
            .buttonWithBorder:focus {
              background-color: red;
              color: white;
              .addstep,
              .plusbutton,
              .button-scratch {
                background: none;
                color: white;
              }
            }
            .oe-text-primary:active,
            .buttonWithBorder:active {
              background: red;
              box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
            }
          }
        }
        p {
          color: white;
          font-size: large; //0.875rem; //14px;
          a {
            text-decoration: underline;
            color: white;
            &:hover {
              color: $primary;
            }
          }
        }
        label {
          color: white;
          font-size: larger;
          a {
            text-decoration: underline;
            color: white;
            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
    .has-wrapper {
      .has-input {
        @include border(1px solid, $input-border-color, top bottom left right);
      }
    }
  }
  .rct-session-wrapper-ls {
    @extend .session-wrapper-ls;
    .row-eq-height {
      align-items: center;
    }
    header {
      height: 100px; //150px;
      background: none !important;
      justify-content: space-around;
      box-shadow: none;
    }
    .session-inner-wrapper {
      .session-body {
        .session-head {
          @media (min-width: 576px) {
            .icon-font-size {
              font-size: 2rem;
            }
          }
          @media (max-width: 575px) and (min-width: 450px) {
            .icon-font-size {
              font-size: 2rem;
            }
          }
          @media (max-width: 450px) {
            .icon-font-size {
              font-size: 1.8rem;
            }
          }

          .rct-block-content {
            a {
              text-decoration: none;
              &:hover {
                color: #008C95;
                text-decoration: underline;
              }
            }
          }
        }
        // background-color: #f5f5f5;//$block-bg;
        // background-color: rgba(49,49,50,1.0);
        border-radius: $block-border-radius;
        padding: 2rem 6.25rem; //30px 100px;
        p {
          color: gray;
          font-size: large; //0.875rem; //14px;
          a {
            text-decoration: underline;
            color: gray;
            &:hover {
              color: gray;
            }
          }
        }
        label {
          color: gray;
          font-size: larger;
          a {
            text-decoration: underline;
            color: gray;
            &:hover {
              color: gray;
            }
          }
        }
      }
    }
    .has-wrapper {
      .has-input {
        @include border(1px solid, $input-border-color, top bottom left right);
      }
    }
  }
  .rct-session-wrapper-theme-support {
    @extend .session-wrapper-theme-support;
    .row-eq-height {
      align-items: center;
    }
    header {
      height: 100px !important; //150px;
      background: none !important;
      justify-content: space-around;
      box-shadow: none;
      .user-details {
        color: var(--headlineTextColor);
        .assignActivityHeading {
          color: var(--headlineTextColor);
        }
        span {
          color:var(--subtextColor);
        }
      }
      .assign-activity{
        button:disabled {
          color: var(--buttonTitleDisabledColor);
          box-shadow: none;
          background-color:var(--buttonDisabledStateColor);
          border: 1px solid var(--buttonDisabledStateColor);
          opacity: 1;
        }
      }
      button:disabled {
        color: var(--buttonTitleDisabledColor);
        box-shadow: none;
        background-color:var(--buttonDisabledStateColor);
        border: 1px solid var(--buttonDisabledStateColor);
        opacity: 1;
        &:hover {
          color: var(--buttonTitleDisabledColor);
          box-shadow: none;
          background-color:var(--buttonDisabledStateColor);
          border: 1px solid var(--buttonDisabledStateColor);
        }
      }
    }
    .assign-activity-search-header {
      min-height: 60px !important;
      padding-bottom: 0px !important;
    }
    .session-inner-wrapper {
      .session-body {
        background-color: var(--screenContentBackgroundColor);
        border-radius: $block-border-radius;
        .session-head {
          @media (min-width: 576px) {
            .icon-font-size {
              font-size: 2rem;
            }
          }
          @media (max-width: 575px) and (min-width: 450px) {
            .icon-font-size {
              font-size: 2rem;
            }
          }
          @media (max-width: 450px) {
            .icon-font-size {
              font-size: 1.8rem;
            }
          }
          .schedule-list-item-odd {
            background-color: #ffffff;
          }
          .primary-color {
            color: var(--primaryButtonNormalStateColor);
          }
          .calender-schedule {
            border: 1px solid var(--primaryButtonNormalStateColor);
          }
          .month-header {
            background-color: var(--primaryButtonNormalStateColor);
            color: var(--buttonTitleColor);
          }

          .titleIcon, .add-new-dropdown {
            background: var(--screenContentBackgroundColor);
          }
          .inputTitleErr {
            color: var(--primaryButtonNormalStateColor);
            background: var(--screenContentBackgroundColor);
          }
          .searchFieldIcon {
            .MuiSvgIcon-colorPrimary {
              color: var(--primaryButtonNormalStateColor);
            }
          }
          .loading-dots { 
            &--dot {
              background-color: var(--primaryButtonNormalStateColor);
            }
          }
          .inputTitle {
            box-shadow: 0 1px 15px rgba(69, 65, 78 ,0.1);
          }
          .inputTitle,
          .desciption {
            color: var(--headlineTextColor);
            background: var(--screenContentBackgroundColor);
            // .MuiInputBase-input{
            //   color: var(--primaryButtonNormalStateColor);
            // }
            // background: var(--buttonTitleColor);
          }
          .assign-activity-search {
              .inputTitle {
              color: var(--headlineTextColor);
              background: var(--screenContentBackgroundColor);
            }
            .MuiInputBase-root {
              color: var(--headlineTextColor);
            }
          }
          .optionsRow {
            background: var(--tabBarColor);
            .bg-white {
              background-color:var(--tabBarButtonsColor) !important;
            }
          }
          .unselectBox {
            box-shadow: 0px 0px 0px #f2f2f2;
          }
          .tabtitle {
            color: var(--tabBarButtonsColor);
          }
          .tabtitle-selected {
            color: var(--tabBarColor);
          }
          .list-card {
            .MuiSvgIcon-colorPrimary {
              color: var(--primaryButtonNormalStateColor);
            }
            .text-decoration-through {
              color: var(--disabledColorOnContent) !important;
            }
            .checkedActivity {
              color: var(--checkboxTintColor);
            }
            .UncheckedActivity {
              color: var(--checkboxTintColor) !important;
            }
            background-color: var(--screenContentBackgroundColor);
            .scheduleTitle,
            .listItem,
            .small,
            .step-input-title,
            .step-input-titleErr,
            .steptitle,
            .step-input-note,
            .MuiIconButton-root {
              color: var(--headlineTextColor);
            }
            .stepnote, .text-muted {
              color: var(--subtextColor);
            }
            .sub-text-color {
              color: var(--subtextColor);
            }
          }
          .list-card-drag {
            .scheduleTitle,
            .listItem,
            .small,
            .step-input-title,
            .step-input-titleErr,
            .steptitle,
            .step-input-note,
            .MuiIconButton-root {
              color: var(--headlineTextColor);
            }
            .stepnote, .text-muted {
              color: var(--subtextColor);
            }
          }
          .rct-block-content {
            color: var(--headlineTextColor);
            background: var(--screenContentBackgroundColor);
            .scheduleTitle,
            .listItem,
            .small,
            .step-input-title,
            .step-input-titleErr,
            .steptitle,
            .step-input-note,
            .stepnote,
            .MuiIconButton-root,
            .text-muted {
              color: var(--headlineTextColor);
            }

            .buttonWithBorder {
              color: var(--secondaryButtonTitleColor);
              border: 2px solid var(--secondaryButtonTitleColor);
              background-color:var(--secondaryButtonNormalStateColor);
            }
            a {
              text-decoration: none;
              &:hover {
                color: var(--buttonTitleColor);
                text-decoration: underline;
              }
            }
            .plusbutton,
            .button-scratch {
              background: none;
              color: var(--primaryButtonNormalStateColor);
            }
            .oe-text-primary {
              background: var(--buttonTitleColor);
              color: var(--primaryButtonNormalStateColor);
              .button-scratch {
                background: none;
                color: var(--primaryButtonNormalStateColor);
              }
            }
            .addstep {
              color: var(--buttonTitleColor);
            }
            .add-step-container {
              .MuiButton-root {
                text-decoration: none;
                background-color: var(--screenContentBackgroundColor);
                .MuiButton-label {
                  color: var(--primaryButtonNormalStateColor);
                }
              }
              .MuiButton-root:hover {
                text-decoration: none;
                background-color: var(--buttonTitleColor);
                .MuiButton-label {
                  color: var(--primaryButtonNormalStateColor);
                }
              }
              @media (hover: none) {
                .MuiButton-root:hover {
                  background-color: transparent;
                  .MuiButton-label {
                    color: var(--primaryButtonNormalStateColor);
                  }
                }
              }
            }
            .oe-text-primary:hover,
            .buttonWithBorder:hover,
            .oe-text-primary:focus,
            .buttonWithBorder:focus {
              background-color: var(--primaryButtonNormalStateColor);
              color: var(--buttonTitleColor);
              .addstep,
              .plusbutton,
              .button-scratch {
                background: none;
                color: var(--buttonTitleColor);
              }
            }
            .oe-text-primary:active,
            .buttonWithBorder:active {
              background-color: var(--buttonTitleColor);
              box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
              color: var(--primaryButtonNormalStateColor);
            }
          }
        }
        p {
          color: var(--primaryButtonNormalStateColor);
          font-size: large; //0.875rem; //14px;
          a {
            text-decoration: underline;
            color: var(--primaryButtonNormalStateColor);
            &:hover {
              color: $primary;
            }
          }
        }
        label {
          color: var(--primaryButtonNormalStateColor);
          font-size: larger;
          a {
            text-decoration: underline;
            color: var(--primaryButtonNormalStateColor);
            &:hover {
              color: $primary;
            }
          }
        }
        .MuiAvatar-root{
          border: 2px solid var(--secondaryButtonTitleColor);
          background-color: var(--secondaryButtonNormalStateColor);
          .MuiSvgIcon-root{
            color: var(--secondaryButtonTitleColor);
          }
        }
      }
      .assign-activities-container {
        background-color: var(--screenContentBackgroundColor) !important;
      }
    }
    .has-wrapper {
      .has-input {
        @include border(1px solid, $input-border-color, top bottom left right);
      }
    }
  }
  .sweet-alert {
    color: var(--primaryButtonNormalStateColor);
    background-color: var(--screenContentBackgroundColor) !important;
    box-shadow: 0 1px 5px 1px var(--primaryButtonNormalStateColor)  !important;
    border: 1px solid var(--primaryButtonNormalStateColor);
    .sweet-alert-text {
        color: var(--primaryButtonNormalStateColor) !important;
    }
    h2 {
      color: var(--headlineTextColor) !important;
     }
    .btn-warning, .btn-info {
      color:var(--buttonTitleColor) !important;
      background: var(--alertButtonsColor);
      border:0px solid rgb(238, 162, 54);
      box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 1px inset, rgba(238, 162, 54,0.6) 0px 0px 8px;
      min-width: 100px;
      min-height: 2.8rem !important;
      padding: 0.6rem 2rem;
    }
    .btn-warning:not(:disabled):not(.disabled):active {
      color:var(--buttonTitleColor) !important;
      background-color: var(--alertButtonsColor);
    }
  }
  .rct-session-wrapper-theme2 {
    @extend .session-wrapper-theme2;
    .row-eq-height {
      align-items: center;
    }
    header {
      height: 100px; //150px;
      background: none !important;
      justify-content: space-around;
      box-shadow: none;
    }
    .session-inner-wrapper {
      .session-body {
        background-color: #f8f8f8;
        border-radius: $block-border-radius;
        padding: 2rem 6.25rem; //30px 100px;
        box-shadow: none;
        .session-head {
          @media (min-width: 576px) {
            .icon-font-size {
              font-size: 2rem;
            }
          }
          @media (max-width: 575px) and (min-width: 450px) {
            .icon-font-size {
              font-size: 2rem;
            }
          }
          @media (max-width: 450px) {
            .icon-font-size {
              font-size: 1.8rem;
            }
          }
          .schedule-list-item-odd {
            background-color: #e8e3e3;
          }
          .primary-color {
            color: #134266;
          }
          .calender-schedule {
            border: 1px solid #134266;
          }
          .month-header {
            background-color: #134266;
            color: white;
          }
          .titleIcon, .add-new-dropdown {
            background: #ffffff;
          }
          .inputTitleErr {
            color: #134266;
            background: #f8f8f8;
          }
          .inputTitle,
          .desciption {
            color: #134266;
            background: #f8f8f8;
            // .MuiInputBase-input{
            //   color: #134266;
            // }
            // background: #FFFFFF;
          }
          .searchFieldIcon {
            .MuiSvgIcon-colorPrimary {
              color: #134266;
            }
          }
          .loading-dots { 
            &--dot {
              background-color: #134266;
            }
          }
          .optionsRow {
            background: #134266;
          }
          .unselectBox {
            box-shadow: 0px 0px 0px #f2f2f2;
          }
          .tabtitle {
            color: #ffffff;
          }
          .tabtitle-selected {
            color: #134266;
          }
          .list-card {
            background-color: #f8f8f8;
            .scheduleTitle,
            .listItem,
            .small,
            .step-input-title,
            .step-input-titleErr,
            .steptitle,
            .step-input-note,
            .stepnote,
            .MuiIconButton-root,
            .text-muted {
              color: #134266;
            }
          }
          .list-card-drag {
            .scheduleTitle,
            .listItem,
            .small,
            .step-input-title,
            .step-input-titleErr,
            .steptitle,
            .step-input-note,
            .stepnote,
            .MuiIconButton-root,
            .text-muted {
              color: #134266;
            }
          }
          .rct-block-content {
            color: #134266;
            background: #f8f8f8;
            .scheduleTitle,
            .listItem,
            .small,
            .step-input-title,
            .step-input-titleErr,
            .steptitle,
            .step-input-note,
            .stepnote,
            .MuiIconButton-root,
            .text-muted {
              color: #134266;
            }
            .buttonWithBorder {
              background: #f8f8f8;
              color: #134266;
              border: 2px solid #134266;
            }
            a {
              text-decoration: none;
              &:hover {
                color: #134266;
                text-decoration: underline;
              }
            }
            .plusbutton,
            .button-scratch {
              background: none;
              color: #134266;
            }
            .addstep,
            .oe-text-primary {
              color: #134266;
            }
            .add-step-container {
              .MuiButton-root {
                text-decoration: none;
                background-color: #f8f8f8;
                .MuiButton-label {
                  color: #134266;
                }
              }
              .MuiButton-root:hover {
                text-decoration: none;
                background-color: #134266;
                .MuiButton-label {
                  color: #ffffff;
                }
              }
              @media (hover: none) {
                .MuiButton-root:hover {
                  background-color: transparent;
                  .MuiButton-label {
                    color: #134266;
                  }
                }
              }
            }
            .oe-text-primary:hover,
            .buttonWithBorder:hover,
            .oe-text-primary:focus,
            .buttonWithBorder:focus {
              background-color: #134266;
              color: white;
              .addstep,
              .plusbutton,
              .button-scratch {
                background: none;
                color: white;
              }
            }
            .oe-text-primary:active,
            .buttonWithBorder:active {
              background-color: #134266;
              box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
            }
          }
        }
        p {
          color: #134266;
          font-size: large; //0.875rem; //14px;
          a {
            text-decoration: underline;
            color: #134266;
            &:hover {
              color: $primary;
            }
          }
        }
        label {
          color: #134266;
          font-size: larger;
          a {
            text-decoration: underline;
            color: #134266;
            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
    .has-wrapper {
      .has-input {
        @include border(1px solid, $input-border-color, top bottom left right);
      }
    }
  }
  .rct-session-wrapper-theme5 {
    @extend .session-wrapper-theme5;
    .row-eq-height {
      align-items: center;
    }
    header {
      height: 100px; //150px;
      background: none !important;
      justify-content: space-around;
      box-shadow: none;
    }
    .session-inner-wrapper {
      .session-body {
        background-color: #e0e0e0;
        border-radius: $block-border-radius;
        padding: 2rem 6.25rem; //30px 100px;
        .session-head {
          @media (min-width: 576px) {
            .icon-font-size {
              font-size: 2rem;
            }
          }
          @media (max-width: 575px) and (min-width: 450px) {
            .icon-font-size {
              font-size: 2rem;
            }
          }
          @media (max-width: 450px) {
            .icon-font-size {
              font-size: 1.8rem;
            }
          }
          .primary-color {
            color: #0f594e;
          }
          .calender-schedule {
            border: 1px solid #0f594e;
          }
          .month-header {
            background-color: #0f594e;
            color: white;
          }
          .titleIcon, .add-new-dropdown {
            background: #ffffff;
          }
          .inputTitleErr {
            color: #0f594e;
            background: #e0e0e0;
          }
          .inputTitle,
          .desciption {
            color: #0f594e;
            background: #e0e0e0;
            // .MuiInputBase-input{
            //   color: #0F594E;
            // }
          }
          .searchFieldIcon {
            .MuiSvgIcon-colorPrimary {
              color: #0f594e;
            }
          }
          .loading-dots { 
            &--dot {
              background-color: #0f594e;
            }
          }
          .optionsRow {
            background: #0f594e;
          }
          .unselectBox {
            box-shadow: 0px 0px 0px #f2f2f2;
          }
          .tabtitle {
            color: #ffffff;
          }
          .tabtitle-selected {
            color: #0f594e;
          }
          .list-card {
            box-shadow: 0 1px 15px 1px rgb(193, 190, 190);
            background-color: #e0e0e0;
            .scheduleTitle,
            .listItem,
            .small,
            .step-input-title,
            .step-input-titleErr,
            .steptitle,
            .step-input-note,
            .stepnote,
            .MuiIconButton-root,
            .text-muted {
              color: #0f594e;
            }
          }
          .list-card-drag {
            box-shadow: 0 1px 15px 1px rgb(193, 190, 190);
            .scheduleTitle,
            .listItem,
            .small,
            .step-input-title,
            .step-input-titleErr,
            .steptitle,
            .step-input-note,
            .stepnote,
            .MuiIconButton-root,
            .text-muted {
              color: #0f594e;
            }
          }
          .rct-block-content {
            color: #0f594e;
            background: #e0e0e0;
            .scheduleTitle,
            .listItem,
            .small,
            .step-input-title,
            .step-input-titleErr,
            .steptitle,
            .step-input-note,
            .stepnote,
            .MuiIconButton-root,
            .text-muted {
              color: #0f594e;
            }
            .buttonWithBorder {
              background: #e0e0e0;
              color: #0f594e;
              border: 2px solid #0f594e;
            }
            a {
              text-decoration: none;
              &:hover {
                color: #0f594e;
                text-decoration: underline;
              }
            }
            .plusbutton,
            .button-scratch {
              background: none;
              color: #0f594e;
            }
            .addstep,
            .oe-text-primary {
              color: #0f594e;
            }
            .add-step-container {
              .MuiButton-root {
                text-decoration: none;
                background-color: #e0e0e0;
                .MuiButton-label {
                  color: #0f594e;
                }
              }
              .MuiButton-root:hover {
                text-decoration: none;
                background-color: #0f594e;
                .MuiButton-label {
                  color: #ffffff;
                }
              }
              @media (hover: none) {
                .MuiButton-root:hover {
                  background-color: transparent;
                  .MuiButton-label {
                    color: #0f594e;
                  }
                }
              }
            }
            .oe-text-primary:hover,
            .buttonWithBorder:hover,
            .oe-text-primary:focus,
            .buttonWithBorder:focus {
              background-color: #0f594e;
              color: white;
              .addstep,
              .plusbutton,
              .button-scratch {
                background: none;
                color: white;
              }
            }
            .oe-text-primary:active,
            .buttonWithBorder:active {
              background-color: #0f594e;
              box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
            }
          }
        }
        p {
          color: #575757;
          font-size: large; //0.875rem; //14px;
          a {
            text-decoration: underline;
            color: #0f594e;
            &:hover {
              color: $primary;
            }
          }
        }
        label {
          color: #0f594e;
          font-size: larger;
          a {
            text-decoration: underline;
            color: #0f594e;
            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
    .has-wrapper {
      .has-input {
        @include border(1px solid, $input-border-color, top bottom left right);
      }
    }
  }
  [class*="circle-btn-"] {
    border-radius: 50px !important;
  }
  .circle-btn-sm {
    min-width: 160px !important;
  }
  .circle-btn-xs {
    min-width: 120px !important;
  }
  .session-slider {
    .slick-slide {
      position: relative;
      img {
        border-radius: 6px;
      }
      .rct-img-overlay {
        position: absolute;
        bottom: 0px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        padding: 0.9375rem 0.9375rem 1.563rem;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        color: $white;
        p {
          margin: 0;
          font-size: 0.875rem;
        }
        h5 {
          font-style: italic;
          font-weight: 500;
          margin-bottom: 5px;
          font-size: 1.25rem;
        }
      }
    }
    .slick-dots {
      text-align: right;
      top: 0;
      height: 30px;
    }
  }
  /*======= Responsive =======*/
  @media (min-width: 1200px) {
    .session-wrapper {
      background-color: rgba(0, 0, 0, 0.9);
    }
    .rct-session-wrapper-ls {
      .session-body {
        background-color: rgba(255, 255, 255, 1);
        [class*="btn-"]:not(.btn-success) {
          background-color: rgba(0, 193, 234, 1);
          font-size: 1.2rem;
        }
      }
    }
    .rct-session-wrapper-theme-support {
      .session-body {
        background-color: #f8f8f8;
        padding: 0rem;
        [class*="btn-"]:not(.btn-success) {
          background-color: #ffffff;
          font-size: 1.2rem;
        }
      }
    }
    .rct-session-wrapper-theme2 {
      .session-body {
        background-color: #f8f8f8;
        [class*="btn-"]:not(.btn-success) {
          background-color: #134266;
          font-size: 1.2rem;
        }
      }
    }
    .rct-session-wrapper-theme5 {
      .session-body {
        background-color: #e0e0e0;
        [class*="btn-"]:not(.btn-success) {
          background-color: #0f594e;
          font-size: 1.2rem;
        }
      }
    }
    .rct-session-wrapper {
      .session-body {
        [class*="btn-"]:not(.btn-success) {
          background-color: red;
          font-size: 1.2rem;
        }
      }
    }
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    .rct-session-wrapper {
      .container {
        max-width: 100%;
      }
      .session-inner-wrapper {
        .session-body {
          padding: 0rem; //2rem 1.875rem;
          [class*="btn-"]:not(.btn-success) {
            //height: 0px !important;
            //width: 35px;
            font-size: 1.2rem; //1rem
            margin-bottom: 5px !important;
            background-color: red;
          }
          p.mb-20,
          .session-head.mb-30 {
            margin-bottom: 0.9375 !important;
          }
        }
      }
    }
    .rct-session-wrapper-ls {
      .container {
        max-width: 100%;
      }
      .session-inner-wrapper {
        .session-body {
          .session-head {
            @media (min-width: 576px) {
              .icon-font-size {
                font-size: 2rem;
              }
            }
            @media (max-width: 575px) and (min-width: 450px) {
              .icon-font-size {
                font-size: 2rem;
              }
            }
            @media (max-width: 450px) {
              .icon-font-size {
                font-size: 1.8rem;
              }
            }
            .rct-block-content {
              a {
                text-decoration: none;
                &:hover {
                  color: #008C95;
                  text-decoration: underline;
                }
              }
            }
          }
          padding: 0rem; //2rem 1.875rem;
          [class*="btn-"]:not(.btn-success) {
            //height: 0px !important;
            //width: 35px;
            font-size: 1.2rem; //1rem
            margin-bottom: 5px !important;
            background-color: rgba(0, 193, 234, 1);
          }
          p.mb-20,
          .session-head.mb-30 {
            margin-bottom: 0.9375 !important;
          }
        }
      }
    }
    .rct-session-wrapper-theme-support {
      .container {
        max-width: 100%;
      }
      .session-inner-wrapper {
        .session-body {
          padding: 0rem; //2rem 1.875rem;
          [class*="btn-"]:not(.btn-success) {
            //height: 0px !important;
            //width: 35px;
            font-size: 1.2rem; //1rem
            margin-bottom: 5px !important;
            background-color: #ffffff;
          }
          p.mb-20,
          .session-head.mb-30 {
            margin-bottom: 0.9375 !important;
          }
        }
      }
    }
    .rct-session-wrapper-theme2 {
      .container {
        max-width: 100%;
      }
      .session-inner-wrapper {
        .session-body {
          padding: 0rem; //2rem 1.875rem;
          [class*="btn-"]:not(.btn-success) {
            //height: 0px !important;
            //width: 35px;
            font-size: 1.2rem; //1rem
            margin-bottom: 5px !important;
            background-color: #134266;
          }
          p.mb-20,
          .session-head.mb-30 {
            margin-bottom: 0.9375 !important;
          }
        }
      }
    }
    .rct-session-wrapper-theme5 {
      .container {
        max-width: 100%;
      }
      .session-inner-wrapper {
        .session-body {
          padding: 0rem; //2rem 1.875rem;
          [class*="btn-"]:not(.btn-success) {
            //height: 0px !important;
            //width: 35px;
            font-size: 1.2rem; //1rem
            margin-bottom: 5px !important;
            background-color: #0f594e;
          }
          p.mb-20,
          .session-head.mb-30 {
            margin-bottom: 0.9375 !important;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .rct-session-wrapper {
      [class*="col-"] {
        max-width: 100%;
        flex: 100%;
      }
      .session-slider {
        display: none;
      }
    }
    .rct-session-wrapper-ls {
      [class*="col-"] {
        max-width: 100%;
        flex: 100%;
      }
      .session-slider {
        display: none;
      }
    }
    .rct-session-wrapper-theme-support {
      [class*="col-"] {
        max-width: 100%;
        flex: 100%;
      }
      .session-slider {
        display: none;
      }
    }
    .rct-session-wrapper-theme2 {
      [class*="col-"] {
        max-width: 100%;
        flex: 100%;
      }
      .session-slider {
        display: none;
      }
    }
    .rct-session-wrapper-theme5 {
      [class*="col-"] {
        max-width: 100%;
        flex: 100%;
      }
      .session-slider {
        display: none;
      }
    }
  }
  @media (max-width: 600px) {
    .error-body {
      .bold {
        font-size: 8.75rem;
      }
      .error-msg {
        font-size: 2.375rem;
      }
    }
  }
  @media (max-width: 575px) {
    .circle-btn-xs {
      min-width: 85px !important;
      padding: 5px 5px 3px !important;
    }
    .rct-session-wrapper {
      .session-head h2 {
        font-size: 1.4rem;
        margin-bottom: 0.4rem;
      }
      header {
        a.mr-15 {
          display: none;
        }
      }
    }
    .rct-session-wrapper-ls {
      .session-head h2 {
        font-size: 1.4rem;
        margin-bottom: 0.4rem;
      }
      header {
        a.mr-15 {
          display: none;
        }
      }
    }
    .rct-session-wrapper-theme-support {
      .session-head h2 {
        font-size: 1.4rem;
        margin-bottom: 0.4rem;
      }
      header {
        a.mr-15 {
          display: none;
        }
      }
    }
    .rct-session-wrapper-theme2 {
      .session-head h2 {
        font-size: 1.4rem;
        margin-bottom: 0.4rem;
      }
      header {
        a.mr-15 {
          display: none;
        }
      }
    }
    .rct-session-wrapper-theme5 {
      .session-head h2 {
        font-size: 1.4rem;
        margin-bottom: 0.4rem;
      }
      header {
        a.mr-15 {
          display: none;
        }
      }
    }
  }
}

/*============ Activity Preview Style =============*/
// Schedule
.MuiDialog-paper {
    margin: 32px;
    position: relative;
    overflow-y: clip; 
}
.scheduleTitle {
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    color: #454D51;
}
.detailsCard {
    padding-left: 0.75rem;
}
.activity-templates-filter-popup, .help-page-popup, .add-schedule-popup {
    .MuiDialog-container {
        .MuiDialog-paper {
            margin: 0px
        }
        .MuiDialog-paperScrollPaper {
            max-height: calc(100% - 90px);
        }
        .MuiDialog-paperFullWidth {
            width: calc(100% - 16px);
        }
    }
}
.filter-buttons-styles {
    .MuiButton-root {
        font-family: 'Open sans', sans-serif;
        font-weight: 400;
        padding: 5px 10px;
        border-radius: 29px;
        font-size: 15px;
    }
}
.detailsCard1 {
    padding-left: 0.75rem;
    margin-right: 5px;
}
.previewdialogbox {
    .MuiDialog-paper {
        margin: 8px;
        position: relative;
        overflow-y: visible;
    }
    .activityPreviewCloseButton {
        position: absolute;
        z-index: 1;
        right: 10px;
        top:10px;
        width: 25px;
        height: 25px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        background-color: #008C95;
        border: 1px solid #ffffff;
        color: #ffffff;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
    }
    .activityPreviewCloseButton:hover {
        color: #008C95;
        background-color: #FFFFFF;
        border: 1px solid #008C95;
    }
    .activity-preview-iframe {
        border-width: 1px;
        margin-bottom: -6px;
        height: 100%;
    }
    .MuiPaper-root {
        height: calc(100% - 80px);
    }
    .MuiDialogContent-root {
        overflow-y: hidden;
        overflow-x: hidden;
    }
}
.take-photo-camera-dialog-popup {
    .loader-overlay {
        z-index: 101;
    }
    .camera-dialog-content {
        margin-bottom: -5px !important;
    }
    .MuiDialog-paperWidthSm {
        max-width: 900px !important;
        width: 100%;
        height: 100%;
    }
}

.camera-popup-dialog {
    .loader-overlay {
        z-index: 101;
    }
    .camera-popup-dialog-content {
        margin-bottom: -5px !important;
        video {
            width: 100%;
            height: 100%;
            // max-height: 700px;
            // max-width: 900px;
            object-fit: cover;
            border-radius: 0px;
        }
        background-color: black;
    }
    .fix-min-style-content {
        min-height: 300px;
        min-width: 350px;
        overflow: hidden;
    }
    .MuiDialog-paperWidthSm {
        max-width: 900px !important;
        width: 100%;
        height: 100%;
    }
    .camera-popup-dialog-actions {
        background-color: gray;
        .take-picture-message-camera {
           font-size: 18px;
        }
        .outer-circle {
            border-radius: 50%;
            width: 75px;
            background-color: rgba(255, 255, 255, 0.4);
            z-index: 1;
            position: relative;
            height: 75px;
        }
        .inner-circle {
            border-radius: 50%;
            height: 44px;
            width: 44px;
            background: #fff;
            margin: -22px 0 0 -22px;
            z-index: 2;
            cursor: pointer;
            position: absolute;
            left: 50%;
            top: 38px;
        }
    }
    .loader-overlay {
        z-index: 101;
    }
}
@media (max-height: 600px) { 
    .previewdialogbox {
        .MuiPaper-root {
            height: calc(100% - 1px);
        }
    }
}
@media (max-height: 650px) { 
    .previewdialogbox {
        .MuiPaper-root {
            height: calc(100% - 20px);
        }
    }
}
@media (min-width: 576px) { 
    .activityPreviewClose {
        top: -9px;
    }
}
@media (max-width: 575px) {
    .activityPreviewClose {
        top: -9px;
    }
}

@media (min-width: 400px) {
    .activity-preview-iframe {
        width:400px
    }
}
@media (max-width: 400px) {
    .activity-preview-iframe {
        width:330px
    }
}
@media (max-width: 320px) {
    .activity-preview-iframe {
        width:295px
    }
}
@media (min-width: 780px) {
    .activity-steps-details {
        margin-right: 12px;
    }
    .calender-schedule {
        width: 60px;
    }
    .descriptionStyle {
        margin-left: 30px;
    }
}
@media (max-width: 780px) and (min-width: 450px) {
    .activity-steps-details {
        margin-right: 12px;
    }
    .calender-schedule {
        width: 60px;
    }
    .descriptionStyle {
        margin-left: 29px;
    }
}
@media (max-width: 450px) {
    .icon-font-size {
        font-size: 1.5rem;
    }
    .activity-icon-preview-expended {
        margin-right: 5px;
    }
    .activity-steps-details {
        margin-right: 7px;
    }
    .calender-schedule {
        width: 45px;
    }
    .descriptionStyle {
        margin-left: 26px;
    }
    .camera-popup-dialog {
        .MuiDialog-paperWidthSm {
            max-width: 900px !important;
            width: 100%;
            height: 100%;
        }
        .MuiDialog-paper {
            margin: 8px;
        }
        .MuiDialog-paperScrollPaper {
            max-height: calc(100% - 16px);
        }
    }
    .take-photo-camera-dialog-popup {
        .MuiDialog-paperWidthSm {
            max-width: 900px !important;
            width: 100%;
            height: 100%;
            max-height: calc(100% - 16px);
        }
        .MuiDialog-paper {
            margin: 8px;
        }
        .MuiDialog-paperScrollPaper {
            max-height: calc(100% - 16px);
        }
    }
  }
.activityPreviewBackbutton {
    position: absolute;
    z-index: 1;
    right: -13px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
.activityPreviewBackbutton:hover,
.activityPreviewBackbutton:focus {
    color: #ffffff;
    background: #008C95;
    .MuiSvgIcon-root,
    .linkButton {
        color: #ffffff;
    }
}

.assignActivityTitle {
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin-top: -1px;
    font-style: normal;
    color: #454D51;
}
.scheduleTitleInActivity {
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
    color: #454D51;
    line-height: 1.5;
}
.icon-font-size {
    font-size: 2.1875rem;
  }

@media (min-width: 576px) {
    .icon-font-size {
        font-size: 2.1875rem;
      }
}
@media (max-width: 575px) and (min-width: 450px) {
    .icon-font-size {
        font-size: 2.1875rem;
      }
}

@media (min-width: 576px) {
    .icon-font-size {
        font-size: 2.1875rem;
      }
}
@media (max-width: 575px) and (min-width: 450px) {
    .icon-font-size {
        font-size: 2.1875rem;
      }
}
.details-count {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: -5px;
    cursor: pointer;
}
.steps-count {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: -10px;
    cursor: pointer;
}
.primary-color {
    color:#008C95;
}
.primary-background {
    background-color:#008C95;
}
.details-title {
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
}
.calender-schedule {
    border: 1px solid #008C95;
    border-radius: 10px;
}
.month-header {
    font-size: 15px;
    font-weight: 500;
    background: #008C95;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: white;
}
.timing-round-icon {
    font-size: 16px;
    font-weight: 400;
    border-radius: 50%;
    width: 24px;
    margin-right: 2px;
    height: 24px;
}
.notification-icon {
    margin-top: 2px;
    color: white;
}
.schedule-date {
    font-size: 20px;
}
.assignActivityHeading {
    margin-top: -3px;
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    color: #454D51;
}
.headerTitle {
    font-family: 'Open sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    color: #454D51;
    padding-top: 10px;
}

.headerButton {
    cursor: pointer;
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    background: #008C95;
    color: white;
    min-width: 88px;
    width: auto;
    height: 40px;
    border: 0px;
    border-radius: 4px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.headerButton:hover {
    background: #008C95;
}
.headerButton:focus {
    background: #008C95;
}
.headerButton:active {
    background: #008C95;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    
}
.tabtitle-selected {
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    color: #454D51;
}
.tabtitle {
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    color: #ffffff;
}
.inputTitle {
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    color: #3D4F57;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0 1px 15px rgba(69, 65, 78, 0.2);
    -webkit-appearance: none;
    -webkit-box-shadow: 0 1px 15px rgba(69, 65, 78, 0.2);
    -moz-box-shadow: 0 1px 15px rgba(69, 65, 78, 0.2);
    // .MuiInputBase-input{
    //     font-family: 'Open sans', sans-serif;
    //     font-weight: 600;
    //     font-size: 18px;
    //     font-style: normal;
    //     color: #3D4F57;
    // }
}
.assign-activity-search {
    .inputTitle {
        font-family: 'Open sans', sans-serif;
        font-weight: 600;
        font-size: 18px;
        font-style: normal;
        color: #3D4F57;
        border-radius: 5px;
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        box-shadow: 0 1px 15px rgba(69, 65, 78, 0.2);
        -webkit-appearance: none;
        -webkit-box-shadow: 0 1px 15px rgba(69, 65, 78, 0.2);
        -moz-box-shadow: 0 1px 15px rgba(69, 65, 78, 0.2);
        // .MuiInputBase-input{
        //     font-family: 'Open sans', sans-serif;
        //     font-weight: 600;
        //     font-size: 18px;
        //     font-style: normal;
        //     color: #3D4F57;
        // }
    }
    .MuiInput-underline:before {
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        position: absolute;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0, 0) 0ms;
        border-bottom: 0px solid rgba(0, 0, 0, 0.42);
        pointer-events: none;
    }
    .MuiInput-underline:after {
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        position: absolute;
        transform: scaleX(0);
        transition: transform 200ms cubic-bezier(0.0, 0, 0, 0) 0ms;
        border-bottom: 0px solid #528693;
        pointer-events: none;
    }
    .MuiInputBase-root:hover {
       border-bottom: 0px solid white;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
         border-bottom: 0px solid rgba(0, 0, 0, 0.87); 
    }
    .MuiInputBase-root {
        font-size: 18px;
        font-family: 'Open sans', sans-serif;
    }
}
.text-decoration-through {
    text-decoration: line-through;
}
.searchFieldIcon {
    height: 43px !important;
}
.desciption {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: #454D51;
    border-radius: 5px;
    margin-top: 5px;
    padding-left: 10px;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .2);
    -webkit-appearance: none;
    // .MuiInputBase-input{
    //     font-family: 'Open sans', sans-serif;
    //     font-weight: 400;
    //     font-size: 14px;
    //     font-style: normal;
    //     color: #454D51;
    //     padding: 4px;
    // }
}
.listItem {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: #454D51;
}
.details-heading {
    font-size: 16px;
}
.detailslistItem {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    color: #454D51;
}
.listItemTitle {
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    color: #454D51;
}
.addSchedule {
    cursor: pointer;
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    color: #008C95;
    border: 2px solid #008C95;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.buttonWithBorder {
    cursor: pointer;
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    background: white;
    color: #008C95;
    border: 2px solid #008C95;
    height: 40px;
    min-width: 152px;
    width: auto;
    border-radius: 4px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.buttonWithBorder:hover, .buttonWithBorder:focus {
    background: #008C95;
    color: white;
}
.buttonWithBorder:active {
    background: #008C95;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    
}
.steplibButton {
    cursor: pointer;
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    color: #008C95;
    border: 0px ;
    background: white;
    height: 40px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.menuEditItem {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #3D4F57
}
.meniDeleteItem {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #DF3C26
}
.scheduleDone {
    cursor: pointer;
    font-family: "open sans", sans-sarif;
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    color: #008C95;
    border: 2px solid #008C95;
    min-width: 120px;
    width: auto;
    height: 40px;
    background: white;
    border-radius: 4px;
}
.scheduleDone:hover, .scheduleDone:focus{
    color:  white;
    background: #008C95;
}
.scheduleDone:active {
    background: #008C95;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    
}
.deleteButton {
    font-size: xx-large;
    color: #F05576;
}
.chipStyle {
    color: rgba(19, 66, 102, 0.4);
    border: 2px solid #008C95;
    background: #FFFFFF;
}
a {
    text-decoration: none;
       &:hover {
         color: #008C95;
         text-decoration: underline;
    }
 }
.cps {
cursor: pointer;
}
.chipSelected {
    color: white;
    background: #008C95;  
}

.weekDays-selected {
    cursor: pointer;
    font-family: 'Open sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    font-style: normal;
    color: white;
    border: 0px ;
    background: #008C95;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.weekDays {
    cursor: pointer;
    font-family: 'Open sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    font-style: normal;
    color: #008C95;
    border: 2px solid #008C95;
    background: #FFFFFF;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.optionsRow{
    background:#008C95;
    border-radius:4px;
    height:42px;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.selectBox{
    cursor: pointer;
    width:49%;
    height:34px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.unselectBox{
    cursor: pointer;
    width:49%;
    height:34px;
    box-shadow: 0px 0px 0px #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.step-input-title, .steptitle {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: #454D51;
}
.step-input-note, .stepnote {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    color: #8F9497
}
.step-input-note, .step-input-title{
    padding: 5px;
}
.filterIconButton {
    cursor: pointer;
    font-family: 'Open sans', sans-serif;
    font-weight: 700;
    font-size: 20px;
    font-style: normal;
    background: #FFFFFF;
    color: #008C95;
    width: 40px;
    height: 40px;
    border: 2px solid #008C95;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.helpbutton {
    cursor: pointer;
    font-family: 'Open sans', sans-serif;
    font-weight: 700;
    font-size: 20px;
    font-style: normal;
    background: #FFFFFF;
    color: #008C95;
    width: 40px;
    height: 40px;
    border: 2px solid #008C95;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.helpbutton:hover, .helpbutton:focus{
    color: #FFFFFF;
    background:#008C95;
}
.helpbutton:active {
    background: #008C95;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    
}
.filter-activities-button {
    cursor: pointer;
    font-family: 'Open sans', sans-serif;
    font-weight: 700;
    font-size: 20px;
    font-style: normal;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.activities-not-filtered {
    background: #FFFFFF;
    color: #008C95;
    border: 2px solid #008C95;
}
.activities-filtered {
    background: #FFFFFF;
    color: #008C95;
    border: 2px solid #008C95;
}
.backbutton {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    color: #008C95;
    border: 2px solid #008C95;
    align-items: center;
    justify-content: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    .MuiSvgIcon-root,.linkButton{
        color: #008C95;
    }
}
.backbutton:hover, .backbutton:focus {
    color: #FFFFFF;
    background:#008C95;
    .MuiSvgIcon-root,.linkButton{
        color: #FFFFFF;
    }
}
.backbutton:active {
    background: #008C95;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    .MuiSvgIcon-root,.linkButton{
        color: #FFFFFF;
    }
    
}
.linkButton {
    cursor: pointer;
    color: #454D51;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
}
.addstep {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: #008C95;
}
.exitDialog1{position: relative;
	max-width: 100%;
	margin: 0px 0px 0.4em;
	padding: 0px;
	color: rgb(89, 89, 89);
	font-weight: 600;
	text-align: center;
	text-transform: none;
	overflow-wrap: break-word;
}
.warningDialog {
	font-weight: 600;
	text-align: center;
	overflow-wrap: break-word;
}
.plusbutton {
    color: #008C95;
}
.titleIcon {
    width: 28px;
    height: 28px;
    background: #85dbe0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .08);
    // margin-top: 6px;
}
.select-font {
    font-size: 11px;
    color: #8F9497;
    margin-bottom: 0px;
}
.select-list {
    padding: 6px 0px 7px;
}
.dilogBox{
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
    border-radius: 8px;
    background: #FFFFFF;
}
.stepContent {
    background: #F7F7F7;
    box-sizing: border-box;
    border-radius: 4px;
}
.stepContentDrag {
    background: #F7F7F7;
    border: 1px dashed #E7E7E7;
    box-sizing: border-box;
    border-radius: 4px;
}
.warningText{
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    color: #454D51;
}
.group-chip{
    font-family: 'Open sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #3D4F57;
    background: #EBF2F5;
}
.button-scratch{
    cursor: pointer;
    font-family: 'Open sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #008C95;
    background: none;
}
.oe-text-primary {
    cursor: pointer;
    color: #008C95;
    box-sizing: border-box;
    border-radius: 5px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.oe-bg-primary {
    background: #EBF2F5;
}
.chip-array-selected {
    .MuiChip-root {
        font-family: 'Open sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #3D4F57;
        background-color: #EBF2F5;
        border: 2px solid #008C95;
    }
    .MuiChip-clickable:hover, .MuiChip-clickable:focus {
        background-color:#EBF2F5;// rgb(206, 206, 206);
        border: 2px solid #008C95;
    }
}
.chip-array {
    .MuiChip-root {
        font-family: 'Open sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #3D4F57;
        background-color: #FFFFFF;
        border: 2px solid #008C95;
    }
    .MuiChip-clickable:hover, .MuiChip-clickable:focus {
        background-color:#FFFFFF;// rgb(206, 206, 206);
        border: 2px solid #008C95;
    }
}
.inputTitleErr {
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    color: #3D4F57;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom:1px solid red;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0 1px 15px rgba(69, 65, 78, .08);
    -webkit-appearance: none;
    -webkit-box-shadow: 0 1px 15px rgba(69, 65, 78, .08);
    -moz-box-shadow: 0 1px 15px rgba(69, 65, 78, .08);
}
.step-input-titleErr{
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    color: #454D51;    
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom:1px solid red;
}
.sweet-alert  {
    .btn {
      padding: 0.3rem 2rem;
      font-size: 1rem;
    }
  }
  .oe-text-primary:hover, .oe-text-primary:focus, .oe-text-primary:active{
    background-color:#008C95;
    color: #FFFFFF;
    .MuiButtonBase-root, .button-scratch{
        background: none;
        color: #FFFFFF;
    }
    .addstep{
        color: #FFFFFF;
    }
  }
.cardbutton:hover, .addstepbutton:hover, .cardbutton:focus, .addstepbutton:focus {
    color: #FFFFFF;
    background:#008C95;
    .MuiButtonBase-root, .button-scratch{
        background: none;
        color: #FFFFFF;
    }
    .addstep{
        color: #FFFFFF;
    }
}
.filterButton {
    border-radius: 20px;
   height: 35px;
}
.cardbutton:active, .addstepbutton:active, .backbutton:active {
    background:#008C95;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
    
}
.list-card{
    border-radius: 5px;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.18);
    border: none;
    margin-bottom: 10px;
    position: relative;
    background-color: white;
}
.list-card-drag{
    border-radius: 5px;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .08);
    border: none;
    margin-bottom: 10px;
    position: relative;
    // border: 1px dashed #E7E7E7;
    box-sizing: border-box;
}
.warning-div {
    width: 80px; 
    height: 80px; 
    border-width: 4px; 
    border-style: solid; 
    border-color: rgb(238, 162, 54); 
    border-radius: 50%; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px; 
    margin-bottom: 0px;
    position: relative; 
    box-sizing: content-box; 
    animation: 0.75s ease 0s infinite alternate none running pulseWarning;
}
.w-span-1{
    position: absolute; 
    width: 5px; 
    height: 47px; 
    left: 50%; 
    top: 10px; 
    border-radius: 2px; 
    margin-left: -2px; 
    background-color: rgb(240, 173, 78); 
    animation: 0.75s ease 0s infinite alternate none running pulseWarningIns;
}
.w-span-2{
    position: absolute; 
    width: 7px; 
    height: 7px; 
    border-radius: 50%; 
    margin-left: -3px; 
    left: 50%; 
    bottom: 10px; 
    background-color: rgb(240, 173, 78); 
    animation: 0.75s ease 0s infinite alternate none running pulseWarningIns;
}
.rct-block-content {
    .add-step-container{
        .MuiButton-root {
            text-decoration: none;
            background-color: #FFFFFF;
            .MuiButton-label {
                color: #008C95;
            }
        }
        .MuiButton-root:hover {
            text-decoration: none;
            background-color: #008C95;
            .MuiButton-label {
                color: #FFFFFF;
            }
        }
        @media (hover: none){
            .MuiButton-root:hover {
                background-color: transparent;
                .MuiButton-label {
                    color: #008C95;
                }
            }
        }
    }
}
.MuiDialog-container {
    .MuiToolbar-root{
        .MuiPickersToolbarText-toolbarBtnSelected {
            font-weight: 900;
        }
        .MuiPickersTimePickerToolbar-ampmLabel{
            font-size: 30px;
        }
    }
    .MuiTypography-h2{
        font-family: 'Open sans', sans-serif;
        font-weight: 400;
        font-size: 40px;
        font-style: normal;
    }
}
.img-object-fit {
    object-fit: contain;
}
.footer-style{
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.canvas-container {
    height: 60vh;
  }

  .add-activity-dilog{
    width:200px;
     border-radius: 8;
     margin:10;
     margin-top:"auto";
     margin-left:'auto'
}
.filter-dilog{
    max-width:500px;
    height:800px;
}
.select-filter{
    border-radius: 16px;
    background: #008C95;
    color:#fff;
    height: 25px;
    cursor: pointer;
}
.unselect-filter{
    border-radius: 16px;
    height: 25px;
    background: #fff;
    cursor: pointer;
    color: #008C95;
    border: 2px solid #008C95;
}
.filter-apply-button{
    border-radius: 16px;
    background: #008C95;
}
.clear-filter-button{
    border-radius: 16px;
    background: white;
    color: #008C95;
    border: 2px solid #008C95;
}
.selected-tab{
    border-bottom:"3px solid white"
}
.list-type{
    background: lightgray;
    border-radius: 8px;
}
.select-list-type{
    background: #008C95;
    border-radius: 8px;
    color: white;
}
.unselect-list-type{
    color:#008C95;
}
.inprogress-heading{
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    background:#008C95;
    margin-bottom:-20px;
}   
.select-inprogress-activity{   
    background:rgb(214,234,243); 
    //background: rgb(255, 255, 255);
    margin-top: -23px;
    border: 2px solid #008C95;
}
.inprogress-activity{   
    //background:rgb(214,234,243); 
    background: rgb(255, 255, 255);
    margin-top: -23px;
    border: 2px solid #008C95;
}
.expired-activity{
    background: rgb(255, 255, 255);
    margin-top: -23px;
} 
.select-activity{
    background:rgb(214,234,243);
    margin-top:-23px;
}
.complete-step{
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.activity-completed{
    background:#008C95;
    height:5px;
    border-radius:2px;
}
.activity-remain{
    background:lightgray;
    height:5px;
    border-bottom-right-radius:2px;
    border-top-right-radius:2px;
}
.summary-list{
    background: rgb(255, 255, 255);
    border-bottom: 3px solid #008C95;
}
.text-500{
    font-weight: 550;
}
.text-400{
    font-weight: 400;
}
.theame-color{
    background:#008C95
}
.theame-text{
    color:#008C95
}
.on-focus{
    cursor: pointer;
}
.form-body-background{
    background-color: #f5f5f5;
}
.form-submit-button{
    height:40px;
    background:#008C95;
}
.activity-details-height{
    min-height:450px;
}
.complete-activity-page-height{
    min-height:470px;
}
.complete-activity-ok-button{
    margin-top:50%;
    background:#008C95;
}
.step-complete-bar{
    width:200px;
}
.complete-activity-rewards-img-text{
    margin-left:25px;
    margin-top:-100px;
    z-index:15;
    font-size: 40px;
}
.width-90{
    width: 90px;
}
.width-27{
    width:30px
}
.margin-t30{
    margin-top: 30%;
}
.margin-t50{
    margin-top:50%
}
.sweet-alert-text{
    font-family:Open sans, sans-serif;
    font-weight:400;
}
.ok-size{
    width:80px;
    font-size:16px;
}
.calender-height{
    height:300px
}
.selected-option{
    border-bottom:3px solid white
}
.diamond-image-index{
    z-index: 10;
}
.diamond-text{
    margin-left:-26px;
    margin-top:8px;
    z-index: 15;
}

.preview-pointer{
    cursor: pointer;
}
.preview-activity-height{
    height: calc(100vh - 190px);
}
.preview-activity-height-empty{
    height: calc(100vh - 160px);
}
.preview-complete-text{
    margin-top:30%;
}
.step-bar{
    width:200px;
}
.preview-step-height{
    height: 300px;
}

@media (max-width: 1023px) {
    .preview-activity-height{
        height: calc(100vh - 186px);
    }
    .preview-activity-height-empty{
        height: calc(100vh - 155px);
    }
}

@media (min-width: 576px) and (max-width: 765px) {
    .preview-activity-height{
        height: calc(100vh - 185px);
        height: calc(100vh - 155px);
    }
}

.schedule-list-item-odd {
    background-color: #f3f1f1;
}
.align-icons {
    position: relative;
    left: -2px;
}
.align-icons-assign {
    margin-top: 1.5px;
}
.assign-activities-list {
    box-shadow: none;
}
.MuiDialogActions-root {
    .primary-button {
        background-color: #008C95;
        color:#FFFFFF;
        border: 1px solid  #008C95;
       
    }
    .MuiButton-root {
        min-width: 110px;
        font-family: 'Open sans', sans-serif;
        min-height: 40px;
        font-weight: 400;
        padding: 6px 7px;
        font-size: 15px;
    }

}
.filter-buttons-styles {
    .filter-button-cancel {
        background-color: white;
        color: #008C95;
        border: 2px solid #008C95;
    }
    .filter-button-apply {
        border-radius: 16px;
        background-color: #008C95;
        color:#fff;
    }
    .MuiButton-root {
        font-family: 'Open sans', sans-serif;
        font-weight: 400;
        padding: 5px 10px;
        border-radius: 29px;
        font-size: 15px;
    }
}

.rct-picker {
    .MuiFormControl-root {
        width: 100%
    }
}
.add-new-dropdown {
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.auto-resize-img {
    max-width: 100%;
    max-height: 120px;
}
